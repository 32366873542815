import { useState, useEffect } from 'react';
import { Row, Col, Card, Breadcrumb, Typography } from 'antd';
import { useKeycloak } from '@react-keycloak/web';
import { useAxios } from '../../../util/AxiosUtil';

import isInRole from '../../../security/IsInRole';

import DomatDailyConsumeCard from './DomatDailyConsumeCard';
import DomatMonthlyConsumeCard from './DomatMonthlyConsumeCard';
import DomatMeasureLog from './DomatMeasureLog';

import { 
  HomeOutlined
} from '@ant-design/icons';

const DomatSensor = ({ sensorName }) => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const { keycloak } = useKeycloak();
  const { Title } = Typography;

  const [sensorData, setSensorData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const customFetch = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_DOMAT_DATA_PATH+'/sensor/'+sensorName)
    .then(response => {
      setSensorData(response.data);
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  useEffect(() => {
    customFetch();
  }, [sensorName]);

  return (
    <>
      <Row>
        <Col span={24} style={{ paddingTop: 10 }}>
          <Breadcrumb>
            <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL}><HomeOutlined /></Breadcrumb.Item>
            {!isLoading ?
            <>

              {isInRole(['enegraf_provider'], keycloak) || isInRole(['enegraf_flat_company'], keycloak) ?
                <>
                  <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/company/"+sessionStorage.getItem('companyId')}>{sessionStorage.getItem('companyName')}</Breadcrumb.Item>
                  <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/manager/"+sessionStorage.getItem('managerId')}>{sessionStorage.getItem('managerName')}</Breadcrumb.Item>
                </>
              : <></>}

              {sessionStorage.getItem('buildingId') && (isInRole(['enegraf_provider'], keycloak) || isInRole(['enegraf_flat_company'], keycloak) || isInRole(['enegraf_flat_manager'], keycloak)) ?
                <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/building/"+sessionStorage.getItem('buildingId')}>{sessionStorage.getItem('buildingName')}</Breadcrumb.Item>
              : <></> }

              {sessionStorage.getItem('unitId') ?
                <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/unit/"+sessionStorage.getItem('unitId')}>{sessionStorage.getItem('unitOwner')}</Breadcrumb.Item>
              : <></> }
              <Breadcrumb.Item>{'Domat ' + sensorData.sensorName}</Breadcrumb.Item>
            </>
            : <></>}
          </Breadcrumb>
        </Col>

        <Col span={24} style={{ paddingTop: 10 }}>
          <Card bordered={true} style={{ paddingLeft: 10 }} bodyStyle={{padding: 10}} loading={isLoading}>
            <Title level={5}>{'Domat: ' + sensorData.sensorName + ' - ' + sensorData.description}</Title>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12} xs={24} xl={12} style={{ paddingTop: 10 }}>
          <DomatDailyConsumeCard sensorData={sensorData} loading={isLoading} />
        </Col>
        <Col span={12} xs={24} xl={12} style={{ paddingTop: 10 }}>
          <DomatMonthlyConsumeCard sensorData={sensorData} loading={isLoading} />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
        <Col span={24}>
          <Card size="small" title="História meraní:" bordered={true} loading={isLoading}>
            <DomatMeasureLog sensorName={sensorName} purpose={sensorData.sensorPurpose} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DomatSensor;