import { Row, Col } from 'antd';

const Unauthorized = () => {

    return (
        <Row style={{ padding: 20 }}>
            <Col span={24}>
                <h1 style={{ fontSize: '28px' }}><b>HTTP Error 401: Unauthorized Access</b></h1>
                <p>You have attempted to access a page for which you are not authorized.</p>
            </Col>
        </Row>
    );
};

export default Unauthorized;