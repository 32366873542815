import { useState } from 'react';
import { Card, DatePicker, Modal, Button, Spin } from 'antd';
import { SettingOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAxios } from '../../../util/AxiosUtil';

import fileSaver from 'file-saver';
import dayjs from 'dayjs';

import UnitZennerMonthlyConsumeChart from './chart/UnitZennerMonthlyConsumeChart';

const UnitZennerMonthlyConsumeCard = ({ unitId, loading }) => {

  const yearFormat = 'YYYY';
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  
  const [isExportModal, setIsExportModal] = useState(false);
  const [exportDate, setExportDate] = useState(dayjs().format(yearFormat));
  const [isLoading, setIsLoading] = useState(false);
  
  const handleExport = async () => {
    setIsLoading(true);
    setIsExportModal(false);
    await axiosClient.get(process.env.REACT_APP_ZENNER_DATA_PATH+'/export/unit/monthly/'+unitId+'?date='+exportDate, {responseType: 'arraybuffer'})
    .then(response => {
      var blob = new Blob([response.data]);
      fileSaver.saveAs(blob, 'export_monthly_'+unitId+'_'+exportDate+'.xlsx');
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(yearFormat);
    if (date === null)
      changedDate = dayjs().format(yearFormat);
    
    setExportDate(changedDate);
  }

  const showExportModal = () => {
    setIsExportModal(true);
  };

  const closeExportModal = () => {
    setIsExportModal(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Card size="small" title="Mesačná spotreba vody:" 
        extra={<>
          <DownloadOutlined title="Exportovať" style={{ cursor: 'pointer', paddingRight: 0 }} onClick={showExportModal}/>
          <SettingOutlined title="Nastaviť alarm" style={{ cursor: 'pointer', display: 'none' }}/>
        </>}
        bordered={true} loading={loading}>
        <UnitZennerMonthlyConsumeChart unitId={unitId} />
      </Card>

      <Modal 
        title="Dátum exportu" 
        visible={isExportModal} 
        onOk={handleExport} 
        onCancel={closeExportModal}
        footer={[
          <Button type="primary" onClick={handleExport} key="saveToExcel">Uložiť do Excelu</Button>
        ]}
        width={200}
      >
        <DatePicker defaultValue={dayjs(exportDate, yearFormat)} onChange={onDateChange} picker="year" format={yearFormat} />
      </Modal>
    </Spin>
  );
};

export default UnitZennerMonthlyConsumeCard;