import isInRole from './IsInRole';
import isEnegrafProvider from './IsEnegrafProvider';
import isEnegrafFlatCompany from './IsEnegrafFlatCompany';
import { useKeycloak } from '@react-keycloak/web';

export default function IsEnegrafFlatManager() {
    
    const { keycloak } = useKeycloak();

    if (isInRole(['enegraf_flat_manager'], keycloak) 
        && (!isEnegrafFlatCompany() && !isEnegrafProvider())) {
        return true;
    }
    return false;
}