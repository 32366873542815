import { useEffect, useState } from 'react';
import { Modal, Form, Input, Alert } from 'antd';
import { useAxios } from '../../util/AxiosUtil';

const ManagerInsert = ({ setVisible, visible, companyId }) => {

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();

    useEffect(() => {
      setFormCompanyId(companyId);
    }, [companyId]);

    const setFormCompanyId = (companyId) => {
      form.setFieldsValue({
        'companyId': companyId
      });
    }

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 12,},
    };

    const validateMessages = {
      required: '${label} je povinné pole!',
      types: { email: '${label} nie je platný email!' }
    };

    const onSubmit = (formVals) => {
      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/manager', formVals)
      .then(response => {
        form.resetFields();
        setFormCompanyId(companyId);
        
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText('Error: ' + error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    return (
      <Modal 
        title="Vytvoriť správcu:" 
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}

        <Form {...layout} form={form} onFinish={onSubmit} validateMessages={validateMessages}>
          <Form.Item name="companyId" rules={[{required: true}]} hidden={true}>
            <Input />
          </Form.Item>

          <br />
          <Form.Item name="name" label="Názov" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <br />
          <Form.Item name="street" label="Ulica" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item name="town" label="Mesto" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item name="postalCode" label="PSČ" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{required: true, type: 'email'}]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
};

export default ManagerInsert;