import { useState } from 'react';
import { Card, DatePicker, Modal, Button, Spin, Form, Input } from 'antd';
import { SettingOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAxios } from '../../../util/AxiosUtil';

import fileSaver from 'file-saver';
import dayjs from 'dayjs';

import ElsysDailyConsumeChart from './chart/ElsysDailyConsumeChart';

const ElsysDailyConsumeCard = ({ sensorData, loading }) => {

  const monthFormat = 'YYYY-MM';
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  
  const [alarmForm] = Form.useForm();
  const [isExportModal, setIsExportModal] = useState(false);
  const [isSettingModal, setIsSettingModal] = useState(false);
  const [exportDate, setExportDate] = useState(dayjs().format(monthFormat));
  const [isLoading, setIsLoading] = useState(false);
  const [dailyAlarmRule, setDailyAlarmRule] = useState([]);

  const handleExport = () => {
    setIsLoading(true);
    setIsExportModal(false);
    
    axiosClient.get(process.env.REACT_APP_ELSYS_DATA_PATH+'/export/sensor/daily/'+sensorData.sensorName+'?date='+exportDate, {responseType: 'arraybuffer'})
    .then(response => {
      var blob = new Blob([response.data]);
      fileSaver.saveAs(blob, 'export_daily_'+sensorData.sensorName+'_'+exportDate+'.xlsx');
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const setSensorAlarm = (formVals) => {
    setIsLoading(true);
    setIsSettingModal(false);

    const formData = {...formVals};
    formData.sensorName = sensorData.sensorName;
    formData.alarmType = 1;
    formData.value = formVals.value ? formVals.value : 0;

    axiosClient.post(process.env.REACT_APP_ELSYS_DATA_PATH+'/alarm', formData)
    .then(response => {
      setIsLoading(false);
    }).catch(
      setIsLoading(false)
    );
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(monthFormat);
    if (date === null)
      changedDate = dayjs().format(monthFormat);
    
    setExportDate(changedDate);
  }

  const showExportModal = () => {
    setIsExportModal(true);
  };

  const closeExportModal = () => {
    setIsExportModal(false);
  };

  const showSettingModal = () => {

    axiosClient.get(process.env.REACT_APP_ELSYS_DATA_PATH+'/alarm?sensorName='+sensorData.sensorName+'&alarmType=1')
    .then(response => {
      setDailyAlarmRule(response.data);
      setIsSettingModal(true);
      setIsLoading(false);
    }).catch(
      setIsLoading(false)
    );
  };

  const closeSettingModal = () => {
    setIsSettingModal(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Card size="small" title="Denná spotreba:" 
        extra={<>
          <DownloadOutlined title="Exportovať" style={{ cursor: 'pointer', paddingRight: 0 }} onClick={showExportModal}/>
        </>}
        bordered={true} loading={loading}>
        <ElsysDailyConsumeChart sensorName={sensorData.sensorName} purpose={sensorData.sensorPurpose} />
      </Card>

      <Modal 
        title="Dátum exportu" 
        visible={isExportModal} 
        onOk={handleExport} 
        onCancel={closeExportModal}
        footer={[
          <Button type="primary" onClick={handleExport} key="saveToExcel">Uložiť do Excelu</Button>
        ]}
        width={200}
      >
        <DatePicker defaultValue={dayjs(exportDate, monthFormat)} onChange={onDateChange} picker="month" format={monthFormat} />
      </Modal>

      <Modal 
        title="Nastavenie alarmu" 
        visible={isSettingModal} 
        onOk={() => {
          alarmForm
            .validateFields()
            .then(values => {
              setSensorAlarm(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={closeSettingModal}
      >
        <Form form={alarmForm} onFinish={setSensorAlarm} labelCol={{span: 6,}} wrapperCol={{span: 18,}}>
          <Form.Item label="Hraničná hodnota" name="value" 
            rules={[
              {
                pattern: new RegExp(/^[0-9]*\.?[0-9]*$/),
                message: 'Nesprávna hodnota',
              },
            ]}
            initialValue={dailyAlarmRule.value}>
            <Input suffix="m3" />
          </Form.Item>
          <Form.Item label="Email pre notifikáciu" name="email" 
            rules={[
              {
                type: 'email',
                message: 'Nesprávny formát emailu',
              }
            ]} initialValue={dailyAlarmRule.email}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default ElsysDailyConsumeCard;