import { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import isEnegrafProvider from '../security/IsEnegrafProvider';
import isEnegrafFlatCompany from '../security/IsEnegrafFlatCompany';
import isEnegrafFlatManager from '../security/IsEnegrafFlatManager';
import isEnegrafFlatOwner from '../security/IsEnegrafFlatOwner';

import { 
    BankOutlined,
    HomeOutlined
} from '@ant-design/icons';

export const EnegrafMenu = ({ closeMenu }) => {

    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState("dashboard");
    
    useEffect(() => {
        if (location.pathname !== '/' && location.pathname !== process.env.REACT_APP_ENEGRAF_URL) {
            let pathname = location.pathname.split("/");
            setSelectedKey(pathname[2]);
        }
    }, [location]);

    return (
        <Menu defaultSelectedKeys={['dashboard']} selectedKeys={selectedKey}>
{/*
            <Menu.Item key="dashboard" icon={<AreaChartOutlined />}>
                <Link to={process.env.REACT_APP_ENEGRAF_URL + "/dashboard"}>Administračný panel</Link>
            </Menu.Item>
*/}
            {isEnegrafProvider() && 
                <Menu.Item key="company" icon={<BankOutlined />} onClick={closeMenu}>
                    <Link to={process.env.REACT_APP_ENEGRAF_URL + "/company"}>Bytové podniky</Link>
                </Menu.Item>
            }

            {isEnegrafFlatCompany() && 
                <Menu.Item key="manager" icon={<BankOutlined />} onClick={closeMenu}>
                    <Link to={process.env.REACT_APP_ENEGRAF_URL + "/manager"}>Správcovské spoločnosti</Link>
                </Menu.Item>
            }

            {isEnegrafFlatManager() && 
                <Menu.Item key="building" icon={<HomeOutlined />} onClick={closeMenu}>
                    <Link to={process.env.REACT_APP_ENEGRAF_URL + "/building"}>Spravované budovy</Link>
                </Menu.Item>
            }

            {isEnegrafFlatOwner() && 
                <Menu.Item key="unit" icon={<HomeOutlined />} onClick={closeMenu}>
                    <Link to={process.env.REACT_APP_ENEGRAF_URL + "/unit"}>Bytové jednotky</Link>
                </Menu.Item>
            }
        </Menu>
    );
  
}