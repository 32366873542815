import { useState } from 'react';
import { Card, DatePicker, Modal, Button, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useAxios } from '../../../util/AxiosUtil';

import fileSaver from 'file-saver';
import dayjs from 'dayjs';

import ElsysMonthlyConsumeChart from './chart/ElsysMonthlyConsumeChart';

const ElsysMonthlyConsumeCard = ({ sensorData, loading }) => {

  const yearFormat = 'YYYY';
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [isExportModal, setIsExportModal] = useState(false);
  const [exportDate, setExportDate] = useState(dayjs().format(yearFormat));
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = () => {
    setIsLoading(true);
    setIsExportModal(false);
    axiosClient.get(process.env.REACT_APP_ELSYS_DATA_PATH+'/export/sensor/monthly/'+sensorData.sensorName+'?date='+exportDate, {responseType: 'arraybuffer'})
    .then(response => {
      var blob = new Blob([response.data]);
      fileSaver.saveAs(blob, 'export_monthly_'+sensorData.sensorName+'_'+exportDate+'.xlsx');
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(yearFormat);
    if (date === null)
      changedDate = dayjs().format(yearFormat);
    
    setExportDate(changedDate);
  }

  const showExportModal = () => {
    setIsExportModal(true);
  };

  const closeExportModal = () => {
    setIsExportModal(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Card size="small" title="Mesačná spotreba:" 
        extra={<>
          <DownloadOutlined title="Exportovať" style={{ cursor: 'pointer' }} onClick={showExportModal}/>
        </>}
        bordered={true} loading={loading}>
        <ElsysMonthlyConsumeChart sensorName={sensorData.sensorName} purpose={sensorData.sensorPurpose} />
      </Card>

      <Modal 
        title="Dátum exportu" 
        visible={isExportModal} 
        onOk={handleExport} 
        onCancel={closeExportModal}
        footer={[
          <Button type="primary" onClick={handleExport} key="saveToExcel">Uložiť do Excelu</Button>
        ]}
        width={200}
      >
        <DatePicker onChange={onDateChange} picker="year" format="YYYY" />
      </Modal>
    </Spin>
  );
};

export default ElsysMonthlyConsumeCard;