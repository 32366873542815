import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from '../../util/AxiosUtil';
import { Card, Row, Col, Table } from 'antd';
import { useKeycloak } from '@react-keycloak/web';

import dayjs from "dayjs";

import isInRole from '../../security/IsInRole';
import Unauthorized from '../../template/error/Unauthorized';
import CompanySearchForm from './CompanySearchForm';

const CompanyList = () => {
    
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const { keycloak } = useKeycloak();

    const dateFromat = "DD.MM.YYYY HH:mm:ss";
    const [isLoading, setIsLoading] = useState(false);
    const [companyListData, setCompanyListData] = useState([]);
    const [limit, setLimit] = useState(5);
    const [pagination, setPagination] = useState({});
    const [formData, setFormData] = useState({});

    useEffect(() => {
        customFetch({offset: 1, limit: limit, orderBy: 'name', order: 'descend'});
    }, []);
    
    const customFetch = async (searchCriteria = {}) => {
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/company', {
        params: searchCriteria
      }).then(response => {
        setCompanyListData(response.data.content);
        setPagination({current: searchCriteria.offset, limit: searchCriteria.limit, orderBy: 'name', order: 'descend', total: response.data.totalElements, pageSizeOptions: ["10","25","50"], showSizeChanger: response.data.totalElements <= 10 ? false : true});
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const onSearch = (form) => {
        setIsLoading(true);

        setFormData(form);
        console.log(form);
        var search = {search: "name:"+form.name};
        console.log(search);
        
        customFetch({
          offset: 1,
          limit: limit,
          orderBy: 'name',
          ...search
        });
    };

    const handleTableChange = (
        pagination,
        filters,
        sorter
      ) => {
        customFetch({
          offset: pagination.current,
          limit: pagination.limit,
          orderBy: sorter.columnKey,
          order: sorter.order,
          ...formData
        });
    };

    const columns = [
        {
          title: 'Názov',
          dataIndex: 'name',
          key: 'name',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.name - b.name,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => 
          <div>
            <div>
              <Link to={process.env.REACT_APP_ENEGRAF_URL + "/company/"+record.id}>{record.name}</Link>
            </div>
          </div>
        },{
          title: 'Mesto',
          dataIndex: 'town',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.town - b.town,
          sortDirections: ['ascend', 'descend', 'ascend'],
          key: 'town',
          responsive: ["lg"]
        },{
          title: 'Ulica',
          dataIndex: 'street',
          key: 'street',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.street - b.street,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["lg"]
        },{
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.email - b.email,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["lg"]
        },{
          title: 'Vytvoril',
          dataIndex: 'createdBy',
          key: 'createdBy',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.createdBy - b.createdBy,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["xl"]
        },{
          title: 'Dátum vytvorenia',
          dataIndex: 'createdOn',
          key: 'createdOn',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.createdOn - b.createdOn,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => record.createdOn ? dayjs(record.createdOn).format(dateFromat) : null,
          responsive: ["xl"]
        },{
          title: 'Zmenil',
          dataIndex: 'modifiedBy',
          key: 'modifiedBy',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.modifiedBy - b.modifiedBy,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["xl"]
        },{
          title: 'Dátum zmeny',
          dataIndex: 'modifiedOn',
          key: 'modifiedOn',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.modifiedOn - b.modifiedOn,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => record.modifiedOn ? dayjs(record.modifiedOn).format(dateFromat) : null,
          responsive: ["xl"]
        }
    ];

    return (
        <Row>
          {isInRole(['enegraf_provider'], keycloak) ?
            <Col span={24}>
              <Row>
                <Col span={24} style={{ paddingBottom: 20 }}>                        
                    <Card size="small">
                      <Table
                        rowKey={companyListData => companyListData.id}
                        columns={columns}
                        dataSource={companyListData}
                        pagination={pagination}
                        onChange={handleTableChange}
                        loading={isLoading}
                        size="small"
                      />
                    </Card>
                </Col>
              </Row>
            </Col>
          : <Unauthorized />}
        </Row>
    );
};

export default CompanyList;