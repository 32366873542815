import { useParams } from "react-router-dom";

import SharkySensor from './sharky/SharkySensor';
import ZennerSensor from './zenner/ZennerSensor';
import ElsysSensor from './elsys/ElsysSensor';
import DomatSensor from './domat/DomatSensor';

const Sensor = () => {

  let params = useParams();
  
  return (
    <>
      {params.type === 'SHARKY' ?
        <SharkySensor parentType={params.parentType} parentId={params.parentId} sensorName={params.id} />
      : params.type === 'ZENNER' ?
        <ZennerSensor parentType={params.parentType} parentId={params.parentId} sensorName={params.id} />
      : params.type === 'ELSYS' ?
        <ElsysSensor parentType={params.parentType} parentId={params.parentId} sensorName={params.id} />
      : params.type === 'DOMAT' ?
        <DomatSensor parentType={params.parentType} parentId={params.parentId} sensorName={params.id} />
      : <></>}
    </>
  );
};

export default Sensor;