import { useState, useEffect } from 'react';
import { useAxios } from './../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { UserContext } from './UserContext';
import { requestFcmToken } from './../../firebase/firebase';

import dayjs from 'dayjs';

const UserContextProvider = ({children}) => {
    
    const dateFormat = "YYYY-MM-DDTHH:mm:ss.SSS";

    const { keycloak } = useKeycloak();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
/*
    keycloak.onTokenExpired = () => { 
        keycloak.updateToken(1).then(function() {
          alert('update token');
        }).catch(function() {
          keycloak.logout()
        })
    };
    */
    
    useEffect(() => {
        customFetch();
        registerFcmToken();
    }, []);
    
    const customFetch = async () => {
        await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/user')
        .then(response => {
            setUserData(response.data);
            setIsLoading(false);
        }).catch('error: ' + console.log);
    };

    const registerFcmToken = async () => {
        const fcmToken = await requestFcmToken();
        await axiosClient.post(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/fcm/token', {
            userName: keycloak.idTokenParsed.preferred_username,
            token: fcmToken,
            createdOn: dayjs().format(dateFormat)
        }).then(response => {
            console.log('Registered token: ' + response.data);
        }).catch('error: ' + console.log);
    };

    return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
};

export default UserContextProvider;