import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const app = initializeApp({
  apiKey: "AIzaSyD5ab-GxcVupRPx331X7xEz6t1xeRxJY7o",
  authDomain: "ef-3eaf1.firebaseapp.com",
  projectId: "ef-3eaf1",
  storageBucket: "ef-3eaf1.appspot.com",
  messagingSenderId: "943522337953",
  appId: "1:943522337953:web:f3af23ac8b6cfb722d4ce0",
  measurementId: "G-4RG7GNQ0SM"
});

const messaging = getMessaging(app);

export const requestFcmToken = async () => {

    const registration = await navigator.serviceWorker
      .register("/enegraf/firebase-messaging-sw.js")
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
        return registration;
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });

    return await getToken(messaging, { 
        vapidKey: 'BD6UcTfWWl8SHuPnT70tD29FxBpVQTHh3zjUG1pGUsrN6nDh5uz69yPTPxn39DbSxkM71x9D5jOMO94Qh16ZYhc',
        serviceWorkerRegistration: registration
      })
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          return currentToken;
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
    })
    .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});