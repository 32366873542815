import { useEffect, useState } from 'react';
import { Modal, Form, Input, Alert, Divider } from 'antd';
import { useAxios } from '../../util/AxiosUtil';

import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import MapMarker from '../../util/MapMarker';
import 'leaflet/dist/leaflet.css';

const BuildingInsert = ({ setVisible, visible, managerId }) => {
    
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();
    
    const [centralMap, setCentralMap] = useState([48.8894822,18.0345196]);
    const [position, setPosition] = useState(centralMap);
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');

    useEffect(() => {
      setFormManagerId(managerId);
    }, [managerId]);

    const setFormManagerId = (managerId) => {
      form.setFieldsValue({
        'managerId': managerId
      });
    }

    const LocationFinder = () => {
      const map = useMapEvents({
          click(e) {
              const { lat, lng } = e.latlng;
              setPosition([lat, lng]);
              setLat(lat);
              setLng(lng);
              form.setFieldsValue({ gps: position });

              axiosClient.get('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+lat+'&lon='+lng)
              .then(response => {
                form.setFieldsValue({ location: 
                  (response.data.address.road ? response.data.address.road : '') + ' ' +
                  (response.data.address.house_number ? response.data.address.house_number : '') + ', ' + 
                  (response.data.address.town ? response.data.address.town : '') +
                  (response.data.address.city ? response.data.address.city : '')
                });
              });

          },
      });
      return null;
    };

    const validateMessages = {
      required: '${label} je povinné pole!',
      types: { email: '${label} nie je platný email!' }
    };

    const onSubmit = (formVals) => {

      formVals.branchId = 0;
      formVals.lat = lat;
      formVals.lng = lng;

      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/building', formVals)
      .then(response => {
        form.resetFields();
        setFormManagerId(managerId);
        
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText('Error: ' + error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    return (
      <Modal 
        title="Vytvoriť budovu:" 
        visible={visible} 
        confirmLoading={isLoading}
        width={500}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >

        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}

        <Divider />
        <Form form={form} onFinish={onSubmit} validateMessages={validateMessages}>
          <Form.Item name="managerId" rules={[{required: true}]} hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item name="name" label="Názov" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item name="gps" label="Poloha" rules={[{required: true, message: 'Vyberte prosím polohu' }]}>
            <MapContainer center={centralMap} zoom={10} scrollWheelZoom={false} style={{ height: 200 }}>
              <LocationFinder />
              <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
              />
              <Marker position={position} icon={ MapMarker }></Marker>
            </MapContainer>

            <br/>
            <Form.Item name="location">
              <Input />
            </Form.Item>
          </Form.Item>

        </Form>
      </Modal>
    );
};

export default BuildingInsert;