import { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Alert, DatePicker, Row, Col, Divider, Spin } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import fileSaver from 'file-saver';

import dayjs from 'dayjs';

const ManagerSensorExport = ({ setVisible, visible, managerId }) => {

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const dateFormat = "YYYY-MM-DD";
    const dateFormatDisp = "DD.MM.YYYY";

    const { RangePicker } = DatePicker;
    const [isLoading, setIsLoading] = useState(false);
    const [exportDate, setExportDate] = useState("");

    const [dateRange, setDateRange] = useState([dayjs().subtract(14,'d'), dayjs()]);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();
    const [sensorType, setSensorType] = useState();
    
    useEffect(() => {
      setFormManagerId(managerId);
    }, []);

    const setFormManagerId = (managerId) => {
      form.setFieldsValue({
        'managerId': managerId
      });
    }

    const changeDateRange = (value) => {
      setDateRange(value);
    };

    const onChangeExportDate = (date, dateString) => {
      setExportDate(dateString);
    };

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 18,},
    };

    const validateMessages = {
      required: '${label} je povinné pole!',
      types: { email: '${label} nie je platný email!' }
    };

    const onSubmit = (formVals) => {
      exportSensor(formVals);
    }

    const exportSensor = (data) => {
      setIsLoading(true);
      axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/export/manager/sensor/'+data.fileType+'/'+data.managerId+'/'+dayjs(data.exportDate[0]).format(dateFormat)+'/'+dayjs(data.exportDate[1]).format(dateFormat), 
        {responseType: 'arraybuffer'}
      ).then(response => {
        var blob = new Blob([response.data]);
        fileSaver.saveAs(blob, 'export_manager.'+data.fileType);
        setIsLoading(false);
        setVisible(false);
      }).catch('error: ' + console.log);
    }

    const onClose = () => {
      form.setFieldsValue({
        'managerId': managerId,
        'sensorType': sensorType
      });
      setVisible(false);
    };

    return (
      <Modal 
        title="Export:" 
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}

        <Divider/>
        <Spin spinning={isLoading}>
          <Row>
            <Col span={24}>
              <Form {...layout} form={form} onFinish={onSubmit} validateMessages={validateMessages} initialValues={{sensorType: "all", fileType: "xlsx", exportDate: dayjs()}}>
                <Form.Item name="managerId" rules={[{required: true}]} hidden={true} >
                  <Input />
                </Form.Item>
                <Form.Item name="exportDate" label="Dátum" rules={[{required: true}]}>
                  <RangePicker 
                    onCalendarChange={(val) => { changeDateRange(val); }}
                    onChange={onChangeExportDate} 
                    format={dateFormatDisp} 
                    style={{width: '100%'}}
                    disabledDate={(current) => { 
                      if (!dateRange) return false;

                      const tooLate = dateRange[0] && current.diff(dateRange[0], 'month') >= 3;
                      const tooEarly = dateRange[1] && dateRange[1].diff(current, 'month') >= 3;
                      return !!tooEarly || !!tooLate || dayjs(dayjs()).isBefore(dayjs(current));
                    }}
                  />
                </Form.Item>
                {/*
                <Form.Item name="sensorType" label="Typ merača" rules={[{required: true}]}>
                  <Select>
                    <Select.Option value="all">Všetky</Select.Option>
                    <Select.Option value="sharky">Sharky</Select.Option>
                    <Select.Option value="zenner">Zenner</Select.Option>
                    <Select.Option value="domat">Domat</Select.Option>
                    <Select.Option value="elsys">Elsys</Select.Option>
                  </Select>
                </Form.Item>
                */}
                <Form.Item name="fileType" label="Typ súboru" rules={[{required: true}]}>
                  <Select defaultValue="Excel">
                    <Select.Option value="xlsx">Excel</Select.Option>
                    <Select.Option value="csv">CSV</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    );
};

export default ManagerSensorExport;