import { useState, useEffect, useCallback } from 'react';
import { Tabs, Button, Card, Row, Col, Tooltip, Breadcrumb, Typography } from 'antd';
import { useParams, useNavigate } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';

import isInRole from '../../security/IsInRole';
import Unauthorized from '../error/Unauthorized';

import ManagerSensorsTable from './../manager_sensor/ManagerSensorsTable';
import ManagerSensorInsert from './../manager_sensor/ManagerSensorInsert';
import ManagerSensorExport from './../manager_sensor/ManagerSensorExport';
import BuildingsTable from './../building/BuildingsTable';
import BuildingInsert from './../building/BuildingInsert';

import { 
  HomeOutlined, PlusCircleOutlined, DownloadOutlined
} from '@ant-design/icons';

const Manager = () => {
    
    let params = useParams();
    const { TabPane } = Tabs;
    const { Title } = Typography;
    const { keycloak } = useKeycloak();

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const navigate = useNavigate();

    const [managerData, setManagerData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedKey, setSelectedKey] = useState();
    const [visibleBuildingForm, setVisibleBuildingForm] = useState(false);
    const [refreshBuildingForm, setRefreshBuildingForm] = useState(false);
    const [visibleSensorForm, setVisibleSensorForm] = useState(false);
    const [refreshSensorForm, setRefreshSensorForm] = useState(false);
    const [visibleSensorExportForm, setVisibleSensorExportForm] = useState(false);

    useEffect(() => {
      customFetch();
    }, [params.id]);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/manager/'+params.id)
      .then(response => {
          setManagerData(response.data);
          setCompanyData(response.data.company);

          sessionStorage.removeItem('buildingId');
          sessionStorage.removeItem('unitId');
          sessionStorage.setItem('managerId', response.data.id);
          sessionStorage.setItem('managerName', response.data.name);
          sessionStorage.setItem('companyId', response.data.company.id);
          sessionStorage.setItem('companyName', response.data.company.name);

          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const showInsertSensorForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleSensorForm(false);
        setRefreshSensorForm(true);
      } else {
        setVisibleSensorForm(value);
      }
    }, []);

    const showInsertBuildingForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleBuildingForm(false);
        setRefreshBuildingForm(true);
      } else {
        setVisibleBuildingForm(value);
      }
    }, []);

    const showSensorExportForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleSensorExportForm(false);
      } else {
        setVisibleSensorExportForm(value);
      }
    }, []);

    const changeTab = (tab) => {
      setSelectedKey(tab);
    };

    return (
      <>
        {isInRole(['enegraf_provider'], keycloak) || isInRole(['enegraf_flat_company'], keycloak) ?
          <>
            <Row>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Breadcrumb>
                  <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL}><HomeOutlined /></Breadcrumb.Item>
                  {!isLoading ?
                  <>
                    {isInRole(['enegraf_provider'], keycloak) ?
                      <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/company/"+companyData.id}>{companyData.name}</Breadcrumb.Item>
                    : <></>}
                    {isInRole(['enegraf_flat_company'], keycloak) ?
                      <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/manager"}>{companyData.name}</Breadcrumb.Item>
                    : <></> }
                    <Breadcrumb.Item>{managerData.name}</Breadcrumb.Item>
                  </>
                  : <></>}
                </Breadcrumb>
              </Col>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Card bordered={true} style={{ paddingLeft: 10 }} bodyStyle={{padding: 10}} loading={isLoading}>
                  <Title level={5}>{managerData.name}</Title>
                </Card>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
              <Col span={24}>
                <Card size="small" loading={isLoading} >
              
                  <Tabs type="card" onTabClick={() => changeTab()} activeKey={selectedKey}>
                    <TabPane tab="Budovy" key="buildingTab">
                      <Card size="small" loading={isLoading}
                        extra={
                            <Button type="text" onClick={showInsertBuildingForm} icon={<PlusCircleOutlined />}>Vytvoriť budovu</Button>
                        }>
                        <BuildingsTable setRefresh={setRefreshBuildingForm} refresh={refreshBuildingForm} managerId={params.id} />
                        <BuildingInsert setVisible={showInsertBuildingForm} visible={visibleBuildingForm} managerId={params.id} />
                      </Card>
                    </TabPane>
                    <TabPane tab="Merače" key="sensorTab">
                      <Card size="small" loading={isLoading}
                        extra={
                            <>
                              <Tooltip title="Pridať merače">
                                <PlusCircleOutlined onClick={showInsertSensorForm} style={{ paddingLeft: 10 }} />
                              </Tooltip>
                              <Tooltip title="Export">
                                <DownloadOutlined onClick={showSensorExportForm} style={{ paddingLeft: 10 }} />
                              </Tooltip>
                            </>
                        }>
                        <ManagerSensorsTable setRefresh={setRefreshSensorForm} refresh={refreshSensorForm} managerId={params.id} />
                        <ManagerSensorInsert setVisible={showInsertSensorForm} visible={visibleSensorForm} manager={managerData} />
                        <ManagerSensorExport setVisible={showSensorExportForm} visible={visibleSensorExportForm} managerId={params.id} />
                      </Card>
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          </>
        : <Unauthorized />}
      </>
    );
};

export default Manager;