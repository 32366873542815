import { useState, useEffect, useContext, useCallback } from 'react';
import { Button, Card, Row, Col, Tabs, Tooltip } from 'antd';

import { UserContext } from '../../context/UserContext';
import BuildingsTable from './BuildingsTable';
import BuildingInsert from './BuildingInsert';
import ManagerSensorsTable from './../manager_sensor/ManagerSensorsTable';
import ManagerSensorExport from './../manager_sensor/ManagerSensorExport';

import { 
  PlusCircleOutlined, DownloadOutlined
} from '@ant-design/icons';

const BuildingList = () => {
    
    const user = useContext(UserContext);
    const { TabPane } = Tabs;

    const [isLoading, setIsLoading] = useState(true);
    const [selectedKey, setSelectedKey] = useState();
    const [managerId, setManagerId] = useState();
    const [visibleForm, setVisibleForm] = useState(false);
    const [refreshForm, setRefreshForm] = useState(false);
    const [refreshSensorForm, setRefreshSensorForm] = useState(false);
    const [visibleSensorExportForm, setVisibleSensorExportForm] = useState(false);

    useEffect(() => {
      if (Object.keys(user).length !== 0) {
        setManagerId(user.allowedManagers[0]);
        setIsLoading(false);
      }
    }, [user]);

    const showInsertForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleForm(false);
        setRefreshForm(true);
      } else {
        setVisibleForm(value);
      }
    }, []);

    const showSensorExportForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleSensorExportForm(false);
      } else {
        setVisibleSensorExportForm(value);
      }
    }, []);

    const changeTab = (tab) => {
      setSelectedKey(tab);
    };

    return (
      <>
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span={24}>
            <Card size="small" loading={isLoading} >
              <Tabs type="card" onTabClick={() => changeTab()} activeKey={selectedKey}>
                <TabPane tab="Budovy" key="buildingTab">
                  <Card size="small" loading={isLoading}
                    extra={
                      <Button type="text" onClick={showInsertForm} icon={<PlusCircleOutlined />}>Vytvoriť budovu</Button>
                    }>
                    <BuildingsTable setRefresh={setRefreshForm} refresh={refreshForm} managerId={managerId} />
                    <BuildingInsert setVisible={showInsertForm} visible={visibleForm} managerId={managerId} />
                  </Card>
                </TabPane>
                <TabPane tab="Merače" key="sensorTab">
                  <Card size="small" loading={isLoading}
                    extra={
                        <>
                          <Tooltip title="Export">
                            <DownloadOutlined onClick={showSensorExportForm} style={{ paddingLeft: 10 }} />
                          </Tooltip>
                        </>
                    }>
                    <ManagerSensorsTable setRefresh={setRefreshSensorForm} refresh={refreshSensorForm} managerId={managerId} />
                    <ManagerSensorExport setVisible={showSensorExportForm} visible={visibleSensorExportForm} managerId={managerId} />
                  </Card>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </>
    );
};

export default BuildingList;