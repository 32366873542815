import { Card } from 'antd';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { MapMarker } from '../../util/MapMarker';
import 'leaflet/dist/leaflet.css';

const BuildingLocationMap = ({ building, loading }) => {

    return (
      <Card size="small" title="Poloha budovy:" bordered={true} loading={loading}>
        <MapContainer style={{ height: 200 }} center={[building.lat, building.lng]} zoom={17} scrollWheelZoom={false}>
          <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[building.lat, building.lng]} icon={ MapMarker }>
              <Popup>{building.name}</Popup>
            </Marker>
        </MapContainer>
      </Card>
    );
};

export default BuildingLocationMap;