import { useState, useEffect } from 'react';
import { Card, Row, Col, Table, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useAxios } from '../../util/AxiosUtil';
import { useNavigate } from "react-router-dom";

const UnitList = () => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [unitListData, setUnitListData] = useState();
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    customFetch({offset: 0, limit: limit, orderBy: 'unit_number', order: 'descend'});
  }, []);
  
  const customFetch = async (searchCriteria = {}) => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/unit/user', {
      params: searchCriteria
    }).then(response => {
      if ((response.data.content).length === 1) {
        navigate(process.env.REACT_APP_ENEGRAF_URL+"/unit/"+response.data.content[0].id);
      } else {
        setUnitListData(response.data.content);
        setPagination({offset: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: searchCriteria.orderBy, order: 'descend', total: response.data.totalElements});
        setIsLoading(false);
      }
    }).catch('error: ' + console.log);
  };
  
  const handleTableChange = (
      pagination,
      filters,
      sorter
    ) => {
      customFetch({
        offset: pagination.current-1,
        limit: pagination.pageSize,
        orderBy: sorter.columnKey,
        order: sorter.order,
        ...formData
      });
  };

  const columns = [
    {
      title: 'Číslo jednotky',
      dataIndex: 'unitNumber',
      key: 'unit_number',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.unitNumber - b.unitNumber,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record, index) => 
      <div>
        <div>
          <Link to={process.env.REACT_APP_ENEGRAF_URL + "/unit/"+record.id}>{record.unitNumber}</Link>
        </div>
      </div>
    },{
      title: 'Poschodie',
      dataIndex: 'unitFloor',
      key: 'unit_floor',
      sorter: (a, b) => a.unitFloor - b.unitFloor,
      sortDirections: ['ascend', 'descend', 'ascend']
    },{
      title: 'Vlastník',
      dataIndex: 'unitOwner',
      key: 'unit_owner',
      sorter: (a, b) => a.unitFloor - b.unitFloor,
      sortDirections: ['ascend', 'descend', 'ascend']
    }
  ];

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24} style={{ paddingBottom: 20 }}>
            {unitListData !== undefined ?
              <Card size="small" title="Odberné miesta:">
                <Table
                  columns={columns}
                  dataSource={unitListData}
                  pagination={pagination}
                  onChange={handleTableChange}
                  loading={isLoading}
                  size="small"
                />
              </Card>
            : <></>}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UnitList;