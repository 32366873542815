import isInRole from './IsInRole';
import isEnegrafProvider from './IsEnegrafProvider';
import { useKeycloak } from '@react-keycloak/web';

export default function IsEnegrafFlatCompany() {
    
    const { keycloak } = useKeycloak();

    if (isInRole(['enegraf_flat_company'], keycloak) && !isEnegrafProvider()) {
        return true;
    }
    return false;
}