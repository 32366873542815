import { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Alert, Transfer, Row, Col, Spin, Divider, Button } from 'antd';
import { useAxios } from '../../util/AxiosUtil';

const BuildingSensorInsert = ({ setVisible, visible, building }) => {

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();
    const [sensorType, setSensorType] = useState();    
    const [availableSensors, setAvailableSensors] = useState();
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [unitSensors, setUnitSensors] = useState([]);
    const [isDeleteUnitSensorWarningOpen, setIsDeleteUnitSensorWarningOpen] = useState(false);

    useEffect(() => {
      setFormBuildingId(building.id);
    }, []);

    const setFormBuildingId = (buildingId) => {
      form.setFieldsValue({
        'buildingId': buildingId
      });
    }

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 18,},
    };

    const validateMessages = {
      required: '${label} je povinné pole!',
      types: { email: '${label} nie je platný email!' }
    };

    const onSubmit = (formVals) => {

      const sensorIds = [...selectedSensors]
      const buildingSensors = {...formVals, sensorIds}

      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/sensor/building',
        buildingSensors
      ).then(response => {
        form.resetFields();
        setFormBuildingId(building.id);

        setIsLoading(false);
        setSensorType();
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText('Error: ' + error.response.data.errors);
      });
    };

    const onClose = () => {
      form.setFieldsValue({
        'buildingId': building.id,
        'sensorType': sensorType
      });
      setVisible(false);
    };

    const changeSensorType = (type) => {
      setSensorType(type);
      getAvailableSensors(type);
      getAssignedSensors(type);
    }

    const getAvailableSensors = (type) => {
      setIsLoading(true);
      axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/sensor/manager/'+building.manager.id+'/building/'+building.id+'?type='+type)
      .then(response => {
        setAvailableSensors(response.data);
        setIsLoading(false);
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response);
      });
    }

    const getAssignedSensors = (type) => {
      setIsLoading(true);
      axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/sensor/building/'+building.id+'?type='+type)
      .then(response => {

        let sensorIds = [];
        response.data.forEach(
          element => sensorIds.push(element.sensorId)
        );

        setSelectedSensors(sensorIds);
        setIsLoading(false);
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response);
      });
    }
    
    const selectSensor = (nextTargetKeys, direction, moveKeys) => {

      if (direction === 'left') {
        let unitSensorsArr = [];
        moveKeys.forEach(k => {
          unitSensorsArr.push(
            ...availableSensors.filter(s => {
              return (s.sensorId === k && s.owner !== '')
            })
          )
        });

        if (Array.isArray(unitSensorsArr) && unitSensorsArr.length) {
          setUnitSensors(unitSensorsArr);
          console.log(unitSensors);
          showDeleteUnitSensorWarning(unitSensors);
        }
      }

      setSelectedSensors(nextTargetKeys);
    };

    const showDeleteUnitSensorWarning = (unitSensors) => {
      setIsDeleteUnitSensorWarningOpen(true);
    };

    const closeDeleteUnitSensorWarning = () => {
      setIsDeleteUnitSensorWarningOpen(false);
    };

    return (
      <>
      <Modal 
        title="Priradiť merače:" 
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}

        <Row>
          <Col span={24}>
            <Divider />
            <Form {...layout} form={form} onFinish={onSubmit} validateMessages={validateMessages}>
              <Form.Item name="buildingId" rules={[{required: true}]} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item name="sensorType" label="Typ merača" rules={[{required: true}]}>
                <Select onChange={changeSensorType} placeholder="Vybrať merač">
                  <Select.Option value="sharky">Sharky</Select.Option>
                  <Select.Option value="zenner">Zenner</Select.Option>
                  <Select.Option value="elsys">Elsys</Select.Option>
                  <Select.Option value="domat">Domat</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={6} />
          <Col span={18}>
            {sensorType &&
              <Spin spinning={isLoading}>
                <Transfer
                  rowKey={record => record.sensorId}
                  dataSource={availableSensors}
                  showSearch
                  targetKeys={selectedSensors}
                  onChange={selectSensor}
                  render={item => item.sensorName}
                  selectAllLabels={[
                    ({ selectedCount, totalCount }) => (
                      <span>
                        {selectedCount} / {totalCount}
                      </span>
                    ), ({ selectedCount, totalCount }) => (
                      <span>
                        {selectedCount} / {totalCount}
                      </span>
                    )
                  ]}
                />
              </Spin>
            }
          </Col>
        </Row>
      </Modal>
      <Modal title="Upozornenie" 
        open={isDeleteUnitSensorWarningOpen} 
        onOk={closeDeleteUnitSensorWarning} 
        onCancel={closeDeleteUnitSensorWarning}
        footer={[
          <Button key="ok" onClick={closeDeleteUnitSensorWarning}>Zavrieť</Button>
        ]}
      >
        <Row>
          <Col span={24}>
            <Divider />
            <p style={{color: 'red'}}><b>Nasledovné merače budú odmazané aj na bytových jednotkách:</b></p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {unitSensors.map((tab, index) => {
              return (
                <p key={tab.sensorName}>{tab.sensorName + ' - Vlastník: ' + tab.owner} </p>
              )
            })}
          </Col>
        </Row>        
      </Modal>
      </>
    );
};

export default BuildingSensorInsert;