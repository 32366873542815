import { useState, useEffect } from 'react';
import { Table, Card } from 'antd';
import { useAxios } from './../../../components/util/AxiosUtil';

import dayjs from 'dayjs';

const Alarm = () => {

  const dateFromat = "DD.MM.YYYY HH:mm:ss";

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [alarmData, setAlarmData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit] = useState(10);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    loadAlarms({offset: 1, limit: limit});
  }, []);

  const loadAlarms = async (searchCriteria = {}) => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/alarm?offset='+searchCriteria.offset)
    .then(response => {
      console.log(response.data);
      setAlarmData(response.data.content);
      setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'descend', total: response.data.totalElements});
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const handleTableChange = (
    pagination,
    filters,
    sorter
  ) => {
    loadAlarms({
      offset: pagination.current,
      limit: pagination.pageSize
    });
  };

  const columns = [
    {
      title: 'Sensor',
      dataIndex: 'sensorName',
      key: 'sensorName'
    },{
      title: 'Sensor Type',
      dataIndex: 'sensorType',
      key: 'sensorType'
    },{
      title: 'Typ alarmu',
      dataIndex: 'typeId',
      key: 'typeId',
      render: (text, record, index) => record.typeId === 1 ? "Denná spotreba" : null
    },{
      title: 'Stav',
      dataIndex: 'value',
      render: (text, record, index) => "Spotreba bola prekročená o " + (record.valueLimit - record.value).toFixed(3) + " m3."
    },{
      title: 'Dátum alarmu',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (text, record, index) => record.createdOn ? dayjs(record.createdOn).format(dateFromat) : null
    }
  ];

  return (
    <Card title="História alarmov:" size="small">
      <Table
        rowKey="id"
        columns={columns}
        dataSource={alarmData}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
        size="small"
      />
    </Card>
  );
};

export default Alarm;