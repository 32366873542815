import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from '../../util/AxiosUtil';
import { Button, Card, Row, Col, Table } from 'antd';
import dayjs from "dayjs";

const UnitsTable = ({ setRefresh, refresh, buildingId }) => {
  
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const dateFromat = "DD.MM.YYYY HH:mm:ss";
    const [isLoading, setIsLoading] = useState(false);
    const [unitListData, setUnitListData] = useState([]);
    const [limit, setLimit] = useState(5);
    const [pagination, setPagination] = useState({});
    const [formData, setFormData] = useState({});

    useEffect(() => {
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
    }, []);
    
    useEffect(() => {
      if (refresh === true)
        customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
      
      setRefresh(false);
    }, [refresh]);

    const customFetch = async (searchCriteria = {}) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/unit?buildingId='+buildingId, {
        params: searchCriteria
      }).then(response => {
        setUnitListData(response.data.content);
        setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'descend', total: response.data.totalElements});
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };
    
    const handleTableChange = (
        pagination,
        filters,
        sorter
      ) => {
        customFetch({
          offset: pagination.current,
          limit: pagination.pageSize,
          orderBy: sorter.columnKey,
          order: sorter.order,
          ...formData
        });
    };

    const columns = [
        {
          title: 'Číslo jednotky',
          dataIndex: 'unitNumber',
          key: 'unitNumber',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.unitNumber - b.unitNumber,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => 
          <div>
            <div>
              <Link to={process.env.REACT_APP_ENEGRAF_URL + "/unit/"+record.id}>{record.unitNumber}</Link>
            </div>
          </div>
        },{
          title: 'Poschodie',
          dataIndex: 'unitFloor',
          key: 'unitFloor',
          sorter: (a, b) => a.unitFloor - b.unitFloor,
          sortDirections: ['ascend', 'descend', 'ascend']
        },{
          title: 'Vlastník',
          dataIndex: 'unitOwner',
          key: 'unitOwner',
          sorter: (a, b) => a.unitFloor - b.unitFloor,
          sortDirections: ['ascend', 'descend', 'ascend']
        },{
          title: 'Vytvoril',
          dataIndex: 'createdBy',
          key: 'createdBy',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.createdBy - b.createdBy,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["xl"]
        },{
          title: 'Dátum vytvorenia',
          dataIndex: 'createdOn',
          key: 'createdOn',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.createdOn - b.createdOn,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => record.createdOn ? dayjs(record.createdOn).format(dateFromat) : null,
          responsive: ["xl"]
        },{
          title: 'Zmenil',
          dataIndex: 'modifiedBy',
          key: 'modifiedBy',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.modifiedBy - b.modifiedBy,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["xl"]
        },{
          title: 'Dátum zmeny',
          dataIndex: 'modifiedOn',
          key: 'modifiedOn',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.modifiedOn - b.modifiedOn,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => record.modifiedOn ? dayjs(record.modifiedOn).format(dateFromat) : null,
          responsive: ["xl"]
        }
    ];

    return (
      <Table
        columns={columns}
        dataSource={unitListData}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
        size="small"
      />
    );
};

export default UnitsTable;