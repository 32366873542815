import React, { useState, useEffect } from 'react';
import { Row, Col, Card  } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { useNavigate } from "react-router-dom";

const FlatManagerDashboard = () => {
    
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        customFetch();
    }, []);
  
    const customFetch = async () => {
        navigate(process.env.REACT_APP_ENEGRAF_URL+'/building');
    };

    return (
        <div style={{ paddingTop: 70, paddingLeft: 20, paddingRight: 20 }}>
            <Row gutter={16}>
                <Col span={6} xs={24} xl={6} style={{ paddingTop: 10 }}>
                    
                </Col>
            </Row>
        </div>
    );
};

export default FlatManagerDashboard;