import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

export const useAxios = (url) => {
  
  const { keycloak } = useKeycloak();
  
  const axiosInstance = axios.create({
    baseURL: url,
  });
  
  axiosInstance.interceptors.request.use(config => {
    config.headers.authorization = 'Bearer ' + keycloak.token;
    return config;
  });
  
  return axiosInstance;
};