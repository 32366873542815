import isInRole from '../security/IsInRole';
import { useKeycloak } from '@react-keycloak/web';

export default function IsEnegrafProvider() {
    
    const { keycloak } = useKeycloak();
    
    if (isInRole(['enegraf_provider'], keycloak)) {
        return true;
    }
    return false;
}