import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from '../../util/AxiosUtil';
import { Button, Space, Input, Table } from 'antd';
import dayjs from "dayjs";

import {
  SearchOutlined, CloseOutlined
} from '@ant-design/icons';

const ManagersTable = ({ setRefresh, refresh, companyId }) => {
  
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const dateFromat = "DD.MM.YYYY HH:mm:ss";
    const [isLoading, setIsLoading] = useState(true);
    const [managerListData, setManagerListData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [pagination, setPagination] = useState({});
    const [formData, setFormData] = useState({});
    const [searchValue, setSearchValue] = useState();

    useEffect(() => {
        customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
    }, []);
    
    useEffect(() => {
      if (refresh === true)
        customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
      
      setRefresh(false);
    }, [refresh]);

    const customFetch = async (searchCriteria = {}) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/manager?companyId='+companyId, {
        params: searchCriteria
      }).then(response => {
        setManagerListData(response.data.content);
        setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'descend', total: response.data.totalElements, pageSizeOptions: ["10","25","50"], showSizeChanger: response.data.totalElements <= 10 ? false : true});
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const handleTableChange = (
        pagination,
        filters,
        sorter
      ) => {
        if (sorter.columnKey === undefined)
          sorter.columnKey = pagination.orderBy;

        customFetch({
          offset: pagination.current,
          limit: pagination.pageSize,
          orderBy: sorter.columnKey,
          order: sorter.order,
          ...formData
        });
    };

    const onSearch = (searchKey) => {
      var search = {search: searchKey+":"+searchValue};
      customFetch({
        offset: 1,
        limit: limit,
        orderBy: 'createdOn',
        ...search
      });
    };

    function getColumnSearchProps(dataIndex) {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={'Hladať'}
              //value={setSearchValue[0]}
              onChange={e => setSearchValue(e.target.value ? [e.target.value] : [])}
              //onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => onSearch(dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Hladať
              </Button>
              <Button
                onClick={() => {
                  confirm({ closeDropdown: true });
                }}
                icon={<CloseOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Zavrieť
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      }
    }

    const columns = [
        {
          title: 'Názov',
          dataIndex: 'name',
          key: 'name',
          //defaultSortOrder: 'descend',
          sorter: (a, b) => a.name - b.name,
          sortDirections: ['ascend', 'descend', 'ascend'],
          ...getColumnSearchProps('name'),
          render: (text, record, index) => 
          <div>
            <div>
              <Link to={process.env.REACT_APP_ENEGRAF_URL + "/manager/"+record.id}>{record.name}</Link>
            </div>
          </div>
        },{
          title: 'Mesto',
          dataIndex: 'town',
          //defaultSortOrder: 'descend',
          sorter: (a, b) => a.town - b.town,
          sortDirections: ['ascend', 'descend', 'ascend'],
          key: 'town',
          ...getColumnSearchProps('town'),
          responsive: ["lg"]
        },{
          title: 'Ulica',
          dataIndex: 'street',
          key: 'street',
          //defaultSortOrder: 'descend',
          sorter: (a, b) => a.street - b.street,
          sortDirections: ['ascend', 'descend', 'ascend'],
          ...getColumnSearchProps('street'),
          responsive: ["lg"]
        },{
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          //defaultSortOrder: 'descend',
          sorter: (a, b) => a.email - b.email,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["lg"]
        },{
          title: 'Vytvoril',
          dataIndex: 'createdBy',
          key: 'createdBy',
          //defaultSortOrder: 'descend',
          sorter: (a, b) => a.createdBy - b.createdBy,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["xl"]
        },{
          title: 'Dátum vytvorenia',
          dataIndex: 'createdOn',
          key: 'createdOn',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.createdOn - b.createdOn,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => record.createdOn ? dayjs(record.createdOn).format(dateFromat) : null,
          responsive: ["xl"]
        },{
          title: 'Zmenil',
          dataIndex: 'modifiedBy',
          key: 'modifiedBy',
          //defaultSortOrder: 'descend',
          sorter: (a, b) => a.modifiedBy - b.modifiedBy,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["xl"]
        },{
          title: 'Dátum zmeny',
          dataIndex: 'modifiedOn',
          key: 'modifiedOn',
          //defaultSortOrder: 'descend',
          sorter: (a, b) => a.modifiedOn - b.modifiedOn,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => record.modifiedOn ? dayjs(record.modifiedOn).format(dateFromat) : null,
          responsive: ["xl"]
        }
    ];

    return (
      <Table
        rowKey={managerListData => managerListData.id}
        columns={columns}
        dataSource={managerListData}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
        size="small"
      />
    );
};

export default ManagersTable;