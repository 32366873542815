import { useState } from 'react';
import { Card, DatePicker, Modal, Button, Spin } from 'antd';
import { SettingOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAxios } from '../../../util/AxiosUtil';

import fileSaver from 'file-saver';
import dayjs from 'dayjs';

import UnitZennerDailyConsumeChart from './chart/UnitZennerDailyConsumeChart';

const UnitZennerDailyConsumeCard = ({ unitId, purpose, loading }) => {

  const monthFormat = 'YYYY-MM';
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  
  const [isExportModal, setIsExportModal] = useState(false);
  const [exportDate, setExportDate] = useState(dayjs().format(monthFormat));
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(purpose === 'TV' ? 'Denná spotreba teplej vody:' : 'Denná spotreba studenej vody:');
  
  const handleExport = async () => {
    setIsLoading(true);
    setIsExportModal(false);
    await axiosClient.get(process.env.REACT_APP_ZENNER_DATA_PATH+'/export/unit/daily/'+unitId+'?purpose='+purpose+'&date='+exportDate, {responseType: 'arraybuffer'})
    .then(response => {
      var blob = new Blob([response.data]);
      fileSaver.saveAs(blob, 'export_daily_'+unitId+'_'+exportDate+'.xlsx');
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(monthFormat);
    if (date === null)
      changedDate = dayjs().format(monthFormat);
    
    setExportDate(changedDate);
  }

  const showExportModal = () => {
    setIsExportModal(true);
  };

  const closeExportModal = () => {
    setIsExportModal(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Card size="small" title={title} 
        extra={<>
          <DownloadOutlined title="Exportovať" style={{ cursor: 'pointer', paddingRight: 0 }} onClick={showExportModal}/>
          <SettingOutlined title="Nastaviť alarm" style={{ cursor: 'pointer', display: 'none' }}/>
        </>}
        bordered={true} loading={loading}>
        <UnitZennerDailyConsumeChart unitId={unitId} purpose={purpose} />
      </Card>

      <Modal 
        title="Dátum exportu" 
        visible={isExportModal} 
        onOk={handleExport} 
        onCancel={closeExportModal}
        footer={[
          <Button type="primary" onClick={handleExport} key="saveToExcel">Uložiť do Excelu</Button>
        ]}
        width={200}
      >
        <DatePicker defaultValue={dayjs(exportDate, monthFormat)} onChange={onDateChange} picker="month" format={monthFormat} />
      </Modal>
    </Spin>
  );
};

export default UnitZennerDailyConsumeCard;