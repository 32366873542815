import { useState, useEffect, useCallback } from 'react';
import { Button, Card, Row, Col, Breadcrumb, Typography } from 'antd';
import { useParams, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';

import isInRole from '../../security/IsInRole';
import Unauthorized from '../../template/error/Unauthorized';

import ManagersTable from '../manager/ManagersTable';
import ManagerInsert from '../manager/ManagerInsert';

import { 
  HomeOutlined, PlusCircleOutlined
} from '@ant-design/icons';

const Company = () => {
    
    let params = useParams();
    const { Title } = Typography;
    const { keycloak } = useKeycloak();

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [companyData, setCompanyData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [visibleForm, setVisibleForm] = useState(false);
    const [refreshForm, setRefreshForm] = useState(false);

    useEffect(() => {
      customFetch();
    }, [params.id]);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/company/'+params.id)
      .then(response => {
          setCompanyData(response.data);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const showInsertForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleForm(false);
        setRefreshForm(true);
      } else {
        setVisibleForm(value);
      }
    }, []);

    return (
      <>
        {isInRole(['enegraf_provider'], keycloak) ?
          <>
            <Row>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Breadcrumb>
                  <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL}><HomeOutlined /></Breadcrumb.Item>
                  {!isLoading ?
                  <>
                    <Breadcrumb.Item>{companyData.name}</Breadcrumb.Item>
                  </>
                  : <></>}
                </Breadcrumb>
              </Col>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Card bordered={true} style={{ paddingLeft: 10 }} bodyStyle={{padding: 10}} loading={isLoading}>
                  <Title level={5}>{companyData.name}</Title>
                </Card>
              </Col>
            </Row>
            
            <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
                <Col span={24}>
                    <Card size="small" title="Správcovia:" loading={isLoading}
                      extra={
                          <Button type="text" onClick={showInsertForm} icon={<PlusCircleOutlined />}>Vytvoriť správcu</Button>
                      }>
                      <ManagersTable setRefresh={setRefreshForm} refresh={refreshForm} companyId={params.id} />
                      <ManagerInsert setVisible={showInsertForm} visible={visibleForm} companyId={params.id} />
                    </Card>
                </Col>
            </Row>
          </>
        : <Unauthorized />}
      </>
    );
};

export default Company;