import { useState, useCallback } from "react";
import { Layout, Row, Col } from 'antd';
import { Drawer, Button } from "antd";
import { MenuOutlined, PoweroffOutlined } from "@ant-design/icons";
import { EnegrafMenu } from './EnegrafMenu';
import { Link } from 'react-router-dom';
import { useAxios } from './../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { requestFcmToken } from './../../firebase/firebase';

import Notification from './../../firebase/Notification';

import logo from './../../static/img/logo.jpg';

const { Header } = Layout;

export const EnegrafHeader = ({ firstname, lastname }) => {
  
    const { keycloak } = useKeycloak();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const [visible, setVisible] = useState(false);

    const [logoffBgColour, setLogoffBgColour] = useState("white")
    const logoffStyles={
        //paddingTop: '13px',
        fontSize: '18px',
        color:`${logoffBgColour}`
    }

    const logout = async () => {
        deleteFcmToken();
        keycloak.logout();
    };

    const closeDrawer = useCallback(() => {
        setVisible(false);
    }, []);

    const deleteFcmToken = async () => {
        const fcmToken = await requestFcmToken();
        await axiosClient.post(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/fcm/token/delete',{
            userName: keycloak.idTokenParsed.preferred_username,
            token: fcmToken
        }).then(response => {
            console.log('Registered token: ' + response);
        }).catch('error: ' + console.log);
    };

    return (
        <Header style={{ position: 'fixed', zIndex: 100000, width: '100%' }}>
            <Row>
                <Col flex="none" xs={2} sm={4} md={6} lg={0}>
                    <nav className="navbar">
                        <Button
                            className="menu"
                            type="primary"
                            icon={<MenuOutlined />}
                            onClick={() => setVisible(true)}
                        />
                        <Drawer
                            title="Menu"
                            placement="left"
                            onClick={() => setVisible(false)}
                            onClose={() => setVisible(false)}
                            open={visible}
                        > 
                            <EnegrafMenu closeMenu={closeDrawer} />
                        </Drawer>
                    </nav>
                </Col>
                <Col flex="none">
                    <Link to={"/"}>
                        <img src={logo} style={{ height : '50px', width: 'auto', paddingTop: '0px'}} alt='ENEGRAF'/>
                    </Link>
                </Col>
                
                <Col flex="auto">
                    <h2 style={{color : 'white', paddingRight: '15px', textAlign: 'right', fontSize: '16px' }}>
                        {firstname} {lastname}
                    </h2>
                </Col>
                <Col style={{ paddingRight: '15px', textAlign: 'right' }}>
                    <Notification />
                </Col>
                <Col>
                    <PoweroffOutlined 
                            style={logoffStyles}
                            onMouseEnter={() => setLogoffBgColour("#999999")}
                            onMouseLeave={() => setLogoffBgColour("white")}
                            onClick={logout} />
                </Col>
            </Row>
        </Header>
    );
  
}