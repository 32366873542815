import { Layout } from 'antd';
import { EnegrafHeader } from './EnegrafHeader';
import { EnegrafMenu } from './EnegrafMenu';
import { Routes, Route } from 'react-router-dom';

import isInRole from '../security/IsInRole';
import Unauthorized from '../template/error/Unauthorized';

import Dashboard from '../template/dashboard/Dashboard';

import Company from '../template/company/Company';
import CompanyList from '../template/company/CompanyList';

import Manager from '../template/manager/Manager';
import ManagerList from '../template/manager/ManagerList';
import ManagerInsert from '../template/manager/ManagerInsert';

import Building from '../template/building/Building';
import BuildingList from '../template/building/BuildingList';
import BuildingInsert from '../template/building/BuildingInsert';

import Unit from '../template/unit/Unit';
import UnitList from '../template/unit/UnitList';

import Sensor from '../template/sensor/Sensor';

import Alarm from '../template/alarm/Alarm';

import { useKeycloak } from '@react-keycloak/web';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_MCS_API_GATEWAY_URL
});

const { Content } = Layout;

const AppLayout = () => {
  
  const { keycloak } = useKeycloak();

  return (
    <Layout>
        {isInRole(['enegraf_web'], keycloak) ? 
          <Layout>
            <EnegrafHeader firstname={keycloak.idTokenParsed.given_name} lastname={keycloak.idTokenParsed.family_name} />
            <Layout>
              <Layout.Sider
                  style={{ marginTop: 64 }}
                  breakpoint={"lg"}
                  theme="light"
                  collapsedWidth={0}
                  trigger={null}
                >
                  <EnegrafMenu />
              </Layout.Sider>
              <Layout>
                <Content>
                  <div style={{ minHeight: '100vh', paddingTop: 80, paddingLeft: 20, paddingRight: 20 }}>
                    <Routes>
                      <Route path={process.env.REACT_APP_ENEGRAF_URL} element={<Dashboard />} exact />
                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/dashboard"} element={<Dashboard />} />

                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/company/:id"} element={<Company />} />
                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/company"} element={<CompanyList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/manager/:companyId/insert"} element={<ManagerInsert />} />
                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/manager/:id"} element={<Manager />} />
                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/manager"} element={<ManagerList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/building/:managerId/insert"} element={<BuildingInsert />} />
                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/building/:id"} element={<Building />} />
                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/building"} element={<BuildingList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/unit/:id"} element={<Unit />} />
                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/unit"} element={<UnitList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/sensor/:parentType/:parentId/:type/:id"} element={<Sensor />} />
                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/sensor/:type/:id"} element={<Sensor />} />

                      <Route path={process.env.REACT_APP_ENEGRAF_URL + "/alarm"} element={<Alarm />} />
                    </Routes>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        : <Unauthorized />}
    </Layout>
    
  );
}

export default AppLayout;