import { useState, useEffect, useContext, useCallback } from 'react';
import { Button, Card, Row, Col } from 'antd';
import { UserContext } from './../../context/UserContext';

import ManagersTable from './ManagersTable';
import ManagerInsert from './ManagerInsert';

import { 
  PlusCircleOutlined
} from '@ant-design/icons';

const ManagerList = () => {
    
    const user = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [companyId, setCompanyId] = useState();
    const [visibleForm, setVisibleForm] = useState(false);
    const [refreshForm, setRefreshForm] = useState(false);

    useEffect(() => {
      if (Object.keys(user).length !== 0) {
        setCompanyId(user.allowedCompanies[0]);
        setIsLoading(false);
      }
    }, [user]);

    const showInsertForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleForm(false);
        setRefreshForm(true);
      } else {
        setVisibleForm(value);
      }
    }, []);

    return (
        <Row>
            <Col span={24}>
              <Row>
                <Col span={24} style={{ paddingBottom: 20 }}>
                    <Card size="small" loading={isLoading}
                      extra={
                          <Button type="text" onClick={showInsertForm} icon={<PlusCircleOutlined />}>Vytvoriť správcu</Button>
                      }>
                      <ManagersTable setRefresh={setRefreshForm} refresh={refreshForm} companyId={companyId} />
                      <ManagerInsert setVisible={showInsertForm} visible={visibleForm} companyId={companyId} />
                    </Card>
                </Col>
              </Row>
            </Col>
        </Row>
    );
};

export default ManagerList;