import { useState, useEffect, useCallback } from 'react';
import { Modal, Card, Tabs, Row, Col, Button, Breadcrumb, Typography } from 'antd';
import { useParams } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useNavigate, Link } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';

import isInRole from '../../security/IsInRole';

import isEnegrafFlatOwner from '../../security/IsEnegrafFlatOwner';

import UnitSensorsTable from './../unit_sensor/UnitSensorsTable';
import UnitSensorInsert from './../unit_sensor/UnitSensorInsert';

import UnitZennerDailyConsumeCard from '../sensor/zenner/UnitZennerDailyConsumeCard';
import UnitZennerMonthlyConsumeCard from '../sensor/zenner/UnitZennerMonthlyConsumeCard';
import UnitElsysDailyConsumeCard from '../sensor/elsys/UnitElsysDailyConsumeCard';
import UnitElsysMonthlyConsumeCard from '../sensor/elsys/UnitElsysMonthlyConsumeCard';

import { 
  HomeOutlined, PlusCircleOutlined
} from '@ant-design/icons';

const Unit = () => {
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    let params = useParams();
    let navigate = useNavigate();
    const { Title } = Typography;
    const { keycloak } = useKeycloak();

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [buildingData, setBuildingData] = useState([]);
    const [unitData, setUnitData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [visibleSensorForm, setVisibleSensorForm] = useState(false);
    const [refreshSensorForm, setRefreshSensorForm] = useState(false);
    const [unitSensorTypeData , setUnitSensorTypeData] = useState([]);
    const [selectedKey, setSelectedKey] = useState();

    useEffect(() => {
      customFetch();
    }, [params.id, refreshSensorForm]);

    const customFetch = async () => {

        try {
          setIsLoading(true);

          await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/unit/'+params.id)
          .then(response => {
            console.log(response.data);
              setUnitSensorTypeData(response.data.unitSensorTypes);
              setBuildingData(response.data.building);
              setUnitData(response.data);
  
              sessionStorage.setItem('buildingId', response.data.building.id);
              sessionStorage.setItem('buildingName', response.data.building.name);
              sessionStorage.setItem('unitId', response.data.id);
              sessionStorage.setItem('unitOwner', response.data.unitOwner);
  
              setIsLoading(false);
          }).catch('error: ' + console.log);

          setIsLoading(false);
        } catch(err) {
          if (err.response.status === 403) {
            setIsModalOpen(true);
          }
          console.log(err);
        }
    };

    const showInsertSensorForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleSensorForm(false);
        setRefreshSensorForm(true);
      } else {
        setVisibleSensorForm(value);
      }
    }, []);

    const changeTab = (tab) => {
      setSelectedKey(tab);
    };

    const handleOk = () => {
      setIsModalOpen(false);
      navigate("/enegraf/building/"+sessionStorage.getItem('buildingId'));
    };

    const getTabContent = (tab) => {

      switch(tab) {
        case 'ZENNER':
          return (
            <>
              <Row gutter={10}>
                  <Col span={12} xs={24} xl={12} style={{ marginTop: 10 }}>
                    <UnitZennerDailyConsumeCard unitId={params.id} purpose="SV" loading={isLoading}/>
                  </Col>
                  <Col span={12} xs={24} xl={12} style={{ marginTop: 10 }}>
                    <UnitZennerDailyConsumeCard unitId={params.id} purpose="TV" loading={isLoading}/>
                  </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <UnitZennerMonthlyConsumeCard unitId={params.id} loading={isLoading} />
                  </Col>
              </Row>
            </>
          )
        case 'SHARKY':
          break;
        case 'ELSYS':
          return (
            <>
              <Row gutter={10}>
                <Col span={12} xs={24} xl={12} style={{ marginTop: 10 }}>
                  <UnitElsysDailyConsumeCard unitId={params.id} purpose="PLN" loading={isLoading}/>
                </Col>
                <Col span={12} xs={24} xl={12} style={{ marginTop: 10 }}>
                  <UnitElsysDailyConsumeCard unitId={params.id} purpose="EL" loading={isLoading}/>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12} xs={24} xl={12} style={{ marginTop: 10 }}>
                  <UnitElsysDailyConsumeCard unitId={params.id} purpose="SV" loading={isLoading}/>
                </Col>
                <Col span={12} xs={24} xl={12} style={{ marginTop: 10 }}>
                  <UnitElsysDailyConsumeCard unitId={params.id} purpose="TV" loading={isLoading}/>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <UnitElsysMonthlyConsumeCard unitId={params.id} loading={isLoading} />
                  </Col>
              </Row>
            </>
          )
      }
    };

    return (
      <>
        <Row>
          <Col span={24} style={{ paddingTop: 10 }}>
            <Breadcrumb>
              <Breadcrumb.Item href="">
                <Link to={process.env.REACT_APP_ENEGRAF_URL}><HomeOutlined /></Link>
              </Breadcrumb.Item>
              {!isLoading ?
              <>
                {isInRole(['enegraf_provider'], keycloak) || isInRole(['enegraf_flat_company'], keycloak) ?
                  <>
                    <Breadcrumb.Item href="">
                      <Link to={process.env.REACT_APP_ENEGRAF_URL + "/company/"+sessionStorage.getItem('companyId')}>{sessionStorage.getItem('companyName')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                      <Link to={process.env.REACT_APP_ENEGRAF_URL + "/manager/"+sessionStorage.getItem('managerId')}>{sessionStorage.getItem('managerName')}</Link>
                    </Breadcrumb.Item>
                  </>
                : <></>}

                {isInRole(['enegraf_provider'], keycloak) || isInRole(['enegraf_flat_company'], keycloak) || isInRole(['enegraf_flat_manager'], keycloak) ?
                  <Breadcrumb.Item href="">
                    <Link to={process.env.REACT_APP_ENEGRAF_URL + "/building/"+buildingData.id}>{buildingData.name}</Link>
                  </Breadcrumb.Item>
                : <></>}

                <Breadcrumb.Item>{unitData.unitOwner}</Breadcrumb.Item>
              </>
              : <></>}
            </Breadcrumb>
          </Col>

          <Col span={24} style={{ marginTop: 10 }}>
            <Card bordered={true} style={{ paddingLeft: 10 }} bodyStyle={{padding: 10}} loading={isLoading}>
              <Title level={5}>{unitData.unitOwner + ' - ' + buildingData.location + ', podlažie: ' + unitData.unitFloor + ', číslo jednotky: ' + unitData.unitNumber}</Title>
            </Card>
          </Col>
        </Row>

        {!isLoading && unitSensorTypeData.length > 1 ?
          <Tabs
            type="card" 
            onTabClick={() => changeTab()} 
            activeKey={selectedKey} 
          >
            {unitSensorTypeData.map((tab, index) => {
              return (
                <>
                  <Tabs type="card" tab={tab.sensorType} key={tab.sensorType}>
                    {getTabContent(tab.sensorType)}
                  </Tabs>
                </>
              );
            })}
          </Tabs>
        : !isLoading && unitSensorTypeData.length > 0 ?
          getTabContent(unitSensorTypeData[0].sensorType)
        : <></>
        }

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
            <Col span={24}>
                <Card size="small" title="Merače:" loading={isLoading}
                  extra={!isEnegrafFlatOwner() && 
                      <Button type="text" onClick={showInsertSensorForm} icon={<PlusCircleOutlined />}>Priradiť merač</Button>
                  }>
                  <UnitSensorsTable setRefresh={setRefreshSensorForm} refresh={refreshSensorForm} unitId={params.id} />
                  <UnitSensorInsert setVisible={showInsertSensorForm} visible={visibleSensorForm} unit={unitData} />
                </Card>
            </Col>
        </Row>

        <Modal title="Error" open={isModalOpen} onOk={handleOk}>
          <p>Užívateľ {keycloak.idTokenParsed.preferred_username} nema pristup k tejto jednotke!</p>
        </Modal>
      </>
    );
};

export default Unit;