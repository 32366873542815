import keycloak from './keycloak'
import { ReactKeycloakProvider } from '@react-keycloak/web';
import AppLayout from './components/layout/AppLayout';
import Spinner from './components/layout/Spinner';
import UserContextProvider from './components/context/UserContextProvider';

const keycloakProviderInitConfig = {
  onLoad: 'login-required',
  checkLoginIframe: false
}

const App = () => {

/*
  const handleOnEvent = async (event, error) => {
    if (event == 'onTokenExpired') {
      alert('logout');
      keycloak.logout();
    }
  }
  onEvent={(event,error) => handleOnEvent(event,error)}
  */

  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig} LoadingComponent={<Spinner/>}>
      <UserContextProvider>
        <AppLayout />
      </UserContextProvider>
    </ReactKeycloakProvider>
  );
}

export default App;