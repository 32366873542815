import { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Alert, DatePicker, Row, Col, Divider, Spin } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import fileSaver from 'file-saver';

import dayjs from 'dayjs';

const BuildingSensorExport = ({ setVisible, visible, building }) => {

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const dateFormat = "YYYY-MM-DD";
    const dateFormatDisp = "DD.MM.YYYY";
    const [isLoading, setIsLoading] = useState(false);
    const [exportDate, setExportDate] = useState("");
    
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();
    const [sensorType, setSensorType] = useState();

    useEffect(() => {
      setFormBuildingId(building.id);
    }, []);

    const setFormBuildingId = (buildingId) => {
      form.setFieldsValue({
        'buildingId': buildingId
      });
    }

    const disabledDate = (current) => {
      return current && current > dayjs().endOf('day');
    }

    const onChangeExportDate = (date, dateString) => {
      setExportDate(dateString);
    };

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 18,},
    };

    const validateMessages = {
      required: '${label} je povinné pole!',
      types: { email: '${label} nie je platný email!' }
    };

    const onSubmit = (formVals) => {

      switch (formVals.sensorType) {
        case 'sharky':
          exportSharky(formVals);
          break;
        case 'zenner':
          exportZenner(formVals);
          break;
        case 'elsys':
          exportElsys(formVals);
          break;
      }
    }

    const exportSharky = (data) => {
      setIsLoading(true);
      axiosClient.get(process.env.REACT_APP_SHARKY_DATA_PATH+'/export/building/sensor/'+data.fileType+'/'+data.buildingId+'/'+dayjs(data.exportDate).format(dateFormat), 
        {responseType: 'arraybuffer'}
      ).then(response => {
        var blob = new Blob([response.data]);
        fileSaver.saveAs(blob, 'export_'+building.name+'.'+data.fileType);
        setIsLoading(false);
        setVisible(false);
      }).catch('error: ' + console.log);
    }

    const exportZenner = (data) => {
      setIsLoading(true);
      var datePath = data.exportDate ? '/' + dayjs(data.exportDate).format(dateFormat) : '';
      axiosClient.get(process.env.REACT_APP_ZENNER_DATA_PATH+'/export/building/sensor/'+data.fileType+'/'+data.buildingId+datePath, 
        {responseType: 'arraybuffer'}
      ).then(response => {
        var blob = new Blob([response.data]);
        fileSaver.saveAs(blob, 'export_'+building.name+'.'+data.fileType);
        setIsLoading(false);
        setVisible(false);
      }).catch('error: ' + console.log);
    }

    const exportElsys = (data) => {
      setIsLoading(true);
      var datePath = data.exportDate ? '/' + dayjs(data.exportDate).format(dateFormat) : '';
      axiosClient.get(process.env.REACT_APP_ELSYS_DATA_PATH+'/export/building/sensor/'+data.fileType+'/'+data.buildingId+datePath, 
        {responseType: 'arraybuffer'}
      ).then(response => {
        var blob = new Blob([response.data]);
        fileSaver.saveAs(blob, 'export_'+building.name+'.'+data.fileType);
        setIsLoading(false);
        setVisible(false);
      }).catch('error: ' + console.log);
    }

    const onClose = () => {
      form.setFieldsValue({
        'buildingId': building.id,
        'sensorType': sensorType
      });
      setVisible(false);
    };

    return (
      <Modal 
        title="Export:" 
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}

        <Divider/>
        <Spin spinning={isLoading}>
          <Row>
            <Col span={24}>
              <Form {...layout} form={form} onFinish={onSubmit} validateMessages={validateMessages} initialValues={{fileType: "xlsx", exportDate: dayjs()}}>
                <Form.Item name="buildingId" rules={[{required: true}]} hidden={true} >
                  <Input />
                </Form.Item>
                <Form.Item name="exportDate" label="Dátum">
                  <DatePicker onChange={onChangeExportDate} disabledDate={disabledDate} format={dateFormatDisp} />
                </Form.Item>
                <Form.Item name="sensorType" label="Typ merača" rules={[{required: true}]}>
                  <Select placeholder="Vybrať typ">
                    <Select.Option value="sharky">Sharky</Select.Option>
                    <Select.Option value="zenner">Zenner</Select.Option>
                    <Select.Option value="elsys">Elsys</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="fileType" label="Typ súboru" rules={[{required: true}]}>
                  <Select defaultValue="Excel">
                    <Select.Option value="xlsx">Excel</Select.Option>
                    <Select.Option value="csv">CSV</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    );
};

export default BuildingSensorExport;