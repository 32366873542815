import { useState, useEffect, useCallback } from 'react';
import { Button, Tabs, Card, Row, Col, Select, Breadcrumb, Typography, Tooltip } from 'antd';
import { useParams, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useNavigate } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';

import isInRole from '../../security/IsInRole';
import Unauthorized from '../error/Unauthorized';

import BuildingLocationMap from './BuildingLocationMap';

import UnitsTable from './../unit/UnitsTable';
import UnitInsert from './../unit/UnitInsert';

import BuildingZennerDailyConsumeCard from './../sensor/zenner/BuildingZennerDailyConsumeCard';
import BuildingZennerMonthlyConsumeCard from '../sensor/zenner/BuildingZennerMonthlyConsumeCard';

import BuildingElsysDailyConsumeCard from './../sensor/elsys/BuildingElsysDailyConsumeCard';
import BuildingElsysMonthlyConsumeCard from '../sensor/elsys/BuildingElsysMonthlyConsumeCard';

import BuildingDomatDailyConsumeCard from './../sensor/domat/BuildingDomatDailyConsumeCard';
import BuildingDomatMonthlyConsumeCard from '../sensor/domat/BuildingDomatMonthlyConsumeCard';

import BuildingDailyConsumeCard from './../sensor/BuildingDailyConsumeCard';

import BuildingSensorsTable from './../building_sensor/BuildingSensorsTable';
import BuildingSensorInsert from './../building_sensor/BuildingSensorInsert';
import BuildingSensorExport from './../building_sensor/BuildingSensorExport';

import {
    HomeOutlined, PlusCircleOutlined, DownloadOutlined
} from '@ant-design/icons';

const Building = () => {
    
    let params = useParams();
    let navigate = useNavigate();
    const { Title } = Typography;
    const { keycloak } = useKeycloak();

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const [buildingData, setBuildingData] = useState([]);
    const [managerData, setManagerData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [buildingListData, setBuildingListData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isBuildingsLoading, setIsBuildingsLoading] = useState(false);
    const [visibleInsertUnitForm, setVisibleInsertUnitForm] = useState(false);
    const [refreshInsertUnitForm, setRefreshInsertUnitForm] = useState(false);
    const [refreshSensorForm, setRefreshSensorForm] = useState(false);
    const [visibleSensorInsertForm, setVisibleSensorInsertForm] = useState(false);
    const [visibleSensorExportForm, setVisibleSensorExportForm] = useState(false);
    const [buildingSensorTypeData , setBuildingSensorTypeData] = useState([]);
    const [selectedKey, setSelectedKey] = useState();

    useEffect(() => {
      customFetch();
    }, [params.id, refreshSensorForm]);

    const customFetch = async () => {

      try {
        setIsLoading(true);

        await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/building/'+params.id)
        .then(response => {
            setBuildingSensorTypeData(response.data.buildingSensorTypes);
            setBuildingData(response.data);
            setManagerData(response.data.manager);
            setCompanyData(response.data.manager.company);

            sessionStorage.setItem('buildingId', response.data.id);
            sessionStorage.setItem('buildingName', response.data.name);

            setIsLoading(false);
        }).catch('error: ' + console.log);

        setIsLoading(false);
      } catch(err) {
        console.log(err);
      }
    };

    const fetchManagerBuildings = async (managerId) => {
      setIsBuildingsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/building?managerId='+managerId, {
        params: { offset: 1, limit: 100 }
      }).then(response => {
        console.log(response.data.content);
        setBuildingListData(response.data.content);
        setIsBuildingsLoading(false);
      }).catch('error: ' + console.log);
    };

    const changeBuilding = async (buildingId) => {
      navigate(process.env.REACT_APP_ENEGRAF_URL+'/building/'+buildingId);
    };

    const showInsertUnitForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleInsertUnitForm(false);
        setRefreshInsertUnitForm(true);
      } else {
        setVisibleInsertUnitForm(value);
      }
    }, []);

    const showSensorInsertForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleSensorInsertForm(false);
        setRefreshSensorForm(true);
      } else {
        setVisibleSensorInsertForm(value);
      }
    }, []);

    const showSensorExportForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleSensorExportForm(false);
      } else {
        setVisibleSensorExportForm(value);
      }
    }, []);

    const changeTab = (tab) => {
      setSelectedKey(tab);
    };

    const groupBy = function(array, key) {
      return array.reduce(function(result, item) {
        (result[item[key]] = result[item[key]] || []).push(item);
        return result;
      }, {});
    };

    const getTabContent = (tab) => {

      switch(tab) {
        case 'ZENNER':
          return (
            <>
              <Row gutter={10} style={{ marginTop: 0 }}>
                <Col span={24}>
                  <BuildingZennerMonthlyConsumeCard buildingId={params.id} loading={isLoading} />
                </Col>
              </Row>
            </>
          )
        case 'SHARKY':
          break;
        case 'ELSYS':
          return (
            <>
              <Row gutter={10} style={{ marginTop: 0 }}>
                <Col span={24}>
                  <BuildingElsysMonthlyConsumeCard buildingId={params.id} loading={isLoading} />
                </Col>
              </Row>
            </>
          )
        case 'DOMAT':
          return (
            <>
              <Row gutter={10} style={{ marginTop: 0 }}>
                <Col span={24}>
                  <BuildingDomatMonthlyConsumeCard buildingId={params.id} loading={isLoading} />
                </Col>
              </Row>
            </>
          )
      }
    };

    return (
      <>
        {isInRole(['enegraf_provider'], keycloak) || isInRole(['enegraf_flat_company'], keycloak) || isInRole(['enegraf_flat_manager'], keycloak) ?
          <>
            <Row>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Breadcrumb>
                  <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL}><HomeOutlined /></Breadcrumb.Item>
                  {!isLoading ?
                  <>
                    {isInRole(['enegraf_provider'], keycloak) || isInRole(['enegraf_flat_company'], keycloak) ?
                      <>
                        <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/company/"+companyData.id}>{companyData.name}</Breadcrumb.Item>
                        <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/manager/"+managerData.id}>{managerData.name}</Breadcrumb.Item>
                      </>
                    : <></>}

                    { isInRole(['enegraf_flat_manager'], keycloak) ?
                      <Breadcrumb.Item href={process.env.REACT_APP_ENEGRAF_URL + "/building"}>{managerData.name}</Breadcrumb.Item>
                    : <></>}

                    <Breadcrumb.Item>{buildingData.name}</Breadcrumb.Item>
                  </>
                  : <></>}
                </Breadcrumb>
              </Col>

              <Col span={24} style={{ paddingTop: 10 }}>    
                  <Card bordered={true} style={{ paddingLeft: 10 }} bodyStyle={{padding: 10}} loading={isLoading}>
                    <Row>
                      <Col flex="3">
                        <Title level={5}>{buildingData.name}</Title>
                      </Col>
                      <Col flex="250px">
                        <Select
                          showSearch
                          placeholder="Prepnúť budovu"
                          optionFilterProp="children"
                          loading={isBuildingsLoading}
                          onFocus={() => fetchManagerBuildings(managerData.id)}
                          onChange={changeBuilding}
                          style={{ width: 240 }}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {buildingListData.map(building =>
                            <option key={building.id} value={building.id}>{building.name}</option>
                          )};
                        </Select>
                      </Col>
                    </Row>
                  </Card>
                </Col>
            </Row>
            
            <Row gutter={16} style={{ marginTop: 10, marginBottom: 0 }}>
              <Col span={24}>
                <BuildingLocationMap building={buildingData} loading={isLoading} />
              </Col>
            </Row>

            { (Object.keys(groupBy(buildingSensorTypeData, 'purpose')).length === 1) ?
              <Row gutter={[10,10]} style={{ marginTop: 10, marginBottom: 10 }}>
                <Col span={24}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "PLN") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="PLN" loading={isLoading} />
                  : <></>}
                  { buildingSensorTypeData.some(type => type['purpose'] === "SV") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="SV" loading={isLoading} />
                  : <></>}
                  { buildingSensorTypeData.some(type => type['purpose'] === "TV") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="TV" loading={isLoading} />
                  : <></>}
                  { buildingSensorTypeData.some(type => type['purpose'] === "EL") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="EL" loading={isLoading} />
                  : <></>}
                </Col>
              </Row>
            :<></> }

            { (Object.keys(groupBy(buildingSensorTypeData, 'purpose')).length === 2) ?
              <Row gutter={[10,10]} style={{ marginTop: 10, marginBottom: 10 }}>
                { buildingSensorTypeData.some(type => type['purpose'] === "PLN") ?
                  <Col span={12}>
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="PLN" loading={isLoading} />
                  </Col>
                : <></>}
                { buildingSensorTypeData.some(type => type['purpose'] === "EL") ?
                  <Col span={12}>
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="EL" loading={isLoading} />
                  </Col>
                : <></>}
                { buildingSensorTypeData.some(type => type['purpose'] === "SV") ?
                  <Col span={12}>
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="SV" loading={isLoading} />
                  </Col>
                : <></>}
                { buildingSensorTypeData.some(type => type['purpose'] === "TV") ?
                  <Col span={12}>
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="TV" loading={isLoading} />
                  </Col>
                : <></>}
              </Row>
            :<></> }

            { (Object.keys(groupBy(buildingSensorTypeData, 'purpose')).length === 3) ?
              <Row gutter={[10,10]} style={{ marginTop: 10, marginBottom: 0 }}>
                <Col span={12}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "SV") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="SV" loading={isLoading} />
                  : <></>}
                </Col>
                <Col span={12}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "TV") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="TV" loading={isLoading} />
                  : <></>}
                </Col>
                <Col span={24}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "PLN") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="PLN" loading={isLoading} />
                  : <></>}
                </Col>
                <Col span={24}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "EL") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="EL" loading={isLoading} />
                  : <></>}
                </Col>
              </Row>
            :<></> }

            { (Object.keys(groupBy(buildingSensorTypeData, 'purpose')).length === 4) ?
              <Row gutter={[10,10]} style={{ marginTop: 10, marginBottom: 10 }}>
                <Col span={12}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "PLN") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="PLN" loading={isLoading} />
                  : <></>}
                </Col>
                <Col span={12}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "EL") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="EL" loading={isLoading} />
                  : <></>}
                </Col>
                <Col span={12}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "SV") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="SV" loading={isLoading} />
                  : <></>}
                </Col>
                <Col span={12}>
                  { buildingSensorTypeData.some(type => type['purpose'] === "TV") ?
                    <BuildingDailyConsumeCard buildingId={params.id} sensorTypes={buildingSensorTypeData} purpose="TV" loading={isLoading} />
                  : <></>}
                </Col>
              </Row>
            :<></> }

            {!isLoading && buildingSensorTypeData.length > 0 ?
              getTabContent(buildingSensorTypeData[0].sensorType)
            : <></>
            }

            <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
                <Col span={24}>
                    <Card size="small" title="Jednotky:" loading={isLoading}
                      extra={
                        <Button type="text" onClick={showInsertUnitForm} icon={<PlusCircleOutlined />}>Vytvoriť jednotku</Button>
                      }>
                      <UnitsTable setRefresh={setRefreshInsertUnitForm} refresh={refreshInsertUnitForm} buildingId={params.id} />
                      <UnitInsert setVisible={showInsertUnitForm} visible={visibleInsertUnitForm} buildingId={params.id} />
                    </Card>
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={24}>
                    <Card size="small" title="Merače:" loading={isLoading}
                      extra={
                        <>
                          <Tooltip title="Priradiť merač">
                            <PlusCircleOutlined onClick={showSensorInsertForm} style={{ paddingLeft: 10 }} />
                          </Tooltip>
                          <Tooltip title="Export">
                            <DownloadOutlined onClick={showSensorExportForm} style={{ paddingLeft: 10 }} />
                          </Tooltip>
                        </>
                      }>
                      <BuildingSensorsTable setRefresh={setRefreshSensorForm} refresh={refreshSensorForm} buildingId={params.id} />
                      <BuildingSensorInsert setVisible={showSensorInsertForm} visible={visibleSensorInsertForm} building={buildingData} />
                      <BuildingSensorExport setVisible={showSensorExportForm} visible={visibleSensorExportForm} building={buildingData} />
                    </Card>
                </Col>
            </Row>
          </>
        : <Unauthorized />}
      </>
    );
};

export default Building;