import { useEffect, useState } from 'react';
import { Modal, Form, Input, Alert, Divider } from 'antd';
import { useAxios } from '../../util/AxiosUtil';

const UnitInsert = ({ setVisible, visible, buildingId }) => {
    
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { Search } = Input;

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();

    useEffect(() => {
      setFormBuildingId(buildingId);
    }, [buildingId]);

    const setFormBuildingId = (buildingId) => {
      form.setFieldsValue({
        'buildingId': buildingId
      });
    };

    const searchFlatOwner = (value) => {

      clearFlatOwner();
      setIsLoading(true);
      axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/user/variableSymbol/'+value)
      .then(response => {
        form.setFieldsValue({
          'userName': response.data.userName,
          'firstName': response.data.firstName,
          'lastName': response.data.lastName
        });
        
        setIsLoading(false);
      }).catch(error => {
        setIsLoading(false);
        clearFlatOwner();
      });
    };

    const clearFlatOwner = () => {
      form.setFieldsValue({
        'userName': '',
        'firstName': '',
        'lastName': ''
      });
    };

    const layout = {
      labelCol: { span: 8, },
      wrapperCol: { span: 12 },
    };

    const validateMessages = {
      required: '${label} je povinné pole!',
      types: { email: '${label} nie je platný email!' }
    };

    const onSubmit = (formVals) => {
      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/unit', formVals)
      .then(response => {
        form.resetFields();
        setFormBuildingId(buildingId);
        
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText('Error: ' + error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    return (
      <Modal 
        title="Vytvoriť jednotku:" 
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >

        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}
        
        <Divider />
        <Form {...layout} form={form} onFinish={onSubmit} validateMessages={validateMessages}>

          <Form.Item name="buildingId" rules={[{required: true}]} hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item name="variableSymbol" label="Variabilný symbol" rules={[{required: true}]}>
            <Search
              size="middle"
              placeholder="Variabilný symbol"
              onSearch={searchFlatOwner}
              allowClear />
          </Form.Item>

          <br />
          <Form.Item name="userName" label="Prihlasovacie meno"
            rules={[
              {
                  required: true,
              },{
                  min: 8,
                  message: 'Prihlasovacie meno musí obsahovať 8 znakov',
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="firstName" label="Meno" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item name="lastName" label="Priezvisko" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>

          <br />
          <Form.Item name="unitFloor" label="Poschodie" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item name="unitNumber" label="Číslo jednotky" rules={[{required: true}]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
};

export default UnitInsert;