import { ConfigProvider } from 'antd';
import skSK from 'antd/es/locale/sk_SK';

import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './style/App.css';

//import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ConfigProvider locale={skSK}>
      <App />
    </ConfigProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
