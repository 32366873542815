import isInRole from '../../security/IsInRole';
import { useKeycloak } from '@react-keycloak/web';

import ProviderDashboard from './ProviderDashboard';
import FlatCompanyDashboard from './FlatCompanyDashboard';
import FlatManagerDashboard from './FlatManagerDashboard';
import FlatOwnerDashboard from './FlatOwnerDashboard';

const Dashboard = () => {
    
    const { keycloak } = useKeycloak();

    if (isInRole(['enegraf_provider'], keycloak)) {
        return <ProviderDashboard />;
    } else if (isInRole(['enegraf_flat_company'], keycloak)) {
        return <FlatCompanyDashboard />;
    } else if (isInRole(['enegraf_flat_manager'], keycloak)) {
        return <FlatManagerDashboard />;
    } else if (isInRole(['enegraf_flat_owner'], keycloak)) {
        return <FlatOwnerDashboard />;
    } else {
        return <></>;
    }

};

export default Dashboard;