import { useState, useEffect } from 'react';
import { useAxios } from '../../../util/AxiosUtil';
import { Column } from '@ant-design/charts';
import dayjs from "dayjs";

const Energy24HoursGraph = (params) => {
  
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const dateFromat = "DD.MM.YYYY HH:mm:ss";
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, [params.sensorId]);

    const customFetch = async () => {
        setIsLoading(true);
        await axiosClient.get(process.env.REACT_APP_SHARKY_DATA_PATH+'/dailyEnergy/'+params.sensorId)
        .then(response => {
          
          response.data.sort(function(a,b){
            var dateA = new Date(a.createdOn).getTime();
            var dateB = new Date(b.createdOn).getTime();
            return dateA > dateB ? 1 : -1;  
          });

          var myJson = [];
          for (var k = 0; k < response.data.length; k++) {
            myJson.push({
              type: dayjs(response.data[k].createdOn).format(dateFromat),
              value: parseFloat(response.data[k].value)
            });
          }
          
          setGraphData(myJson);
          setIsLoading(false);
        }).catch('error: ' + console.log);
    };

    var config = {
      data: graphData,
      xField: 'type',
      yField: 'value',
      height: 200,
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        value: { alias: 'Energy MWh' },
      },
    };

    return (
        <Column {...config} loading={isLoading} />
    );
};

export default Energy24HoursGraph;