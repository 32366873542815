import { useState, useEffect } from 'react'
import { Row, Col, Divider, Badge, Button, Popover, Typography, List } from 'antd';
import { useKeycloak } from '@react-keycloak/web';
import { useAxios } from './../components/util/AxiosUtil';
import { requestFcmToken, onMessageListener } from './firebase';
import { BellOutlined, AlertOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { swing } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import dayjs from 'dayjs';

const Notification = () => {
  
  const dateFromatDisp = "DD.MM.yyyy HH:mm:ss";

  const { Title } = Typography;
  let navigate = useNavigate();

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [hovered, setHovered] = useState(false);
  const [alarms, setAlarms] = useState();
  const [alarmSize, setAlarmSize] = useState();
  const [ringBell, setRingBell] = useState(Radium.keyframes(swing, 'swing'));

  useEffect(() => {
    requestFcmToken();
    loadAlarms();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRingBell(ringBell !== '' ? '' : Radium.keyframes(swing, 'swing'));
    }, 2000);
    return () => clearInterval(interval);
  }, [ringBell]);

  const styles = {
    swing: {
      animation: 'x 1s',
      animationName: ringBell,
      fontSize: '20px',
      color: '#ee1b26'
    }
  }
  
  onMessageListener()
    .then((payload) => {
      loadAlarms();
    })
    .catch((err) => console.log('failed: ', err));

  const loadAlarms = async () => {
    await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/alarm?search=accepted:0')
    .then(response => {
        setAlarms(response.data.content);
        setAlarmSize(response.data.content.length);
    }).catch('error: ' + console.log);
  };

  const clearAlarms = async () => {
    await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/alarm/clear')
    .catch('error: ' + console.log);
  };

  const openAlarms = async () => {
    //setHovered(false);
    clearAlarms();
    setAlarmSize(0);
    navigate(process.env.REACT_APP_ENEGRAF_URL + "/alarm");
  };

  const content = (
    <>
      <Row align="middle">
        <Col>
          <List
            size="small"
            dataSource={alarms}
            renderItem={(item) => (
              <List.Item>
                <u>{item.sensorName}</u> {' - ' + dayjs(item.createdOn).format(dateFromatDisp)}
                <List.Item.Meta
                  description={"Limit prekročený o " + (item.value - item.valueLimit) + " m3."}
                />
              </List.Item>
            )}
          />
        </Col>
        <Divider style={{ marginTop: 5, marginBottom: 15 }}/>
      </Row>
      <Row justify="center">
        <Col>
          <Button type="primary" onClick={() => openAlarms()}>Zobraziť upozornenia</Button>
        </Col>
      </Row>
    </>
  );

  const emptyContent = (
    <>
      <Row align="middle">
        <Col>
          <Title level={5} style={{ paddingTop: 20 }}>Momentálne nie su žiadne upozornenia  </Title>
        </Col>
        <Divider />
      </Row>
      <Row justify="center">
        <Col>
          <Button type="primary" onClick={() => openAlarms()}>Zobraziť upozornenia</Button>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {alarmSize > 0 ?
        <StyleRoot style={{ marginTop: 1 }}>
            <Badge count={alarmSize} size="small">
              <div style={styles.swing}>
                <BellOutlined onClick={() => openAlarms()} />
              </div>
            </Badge>
        </StyleRoot>
      : 
        <StyleRoot style={{ marginTop: 1 }}>
          <div>
            <BellOutlined onClick={() => openAlarms()} style={{ fontSize: '20px', color: 'white' }} />
          </div>
        </StyleRoot>
      }
    </>
  )
}

export default Notification;