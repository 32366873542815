import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from '../../util/AxiosUtil';
import { Button, Space, Input, Table } from 'antd';

import {
  SearchOutlined, CloseOutlined
} from '@ant-design/icons';

const BuildingSensorsTable = ({ setRefresh, refresh, buildingId }) => {
  
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const dateFromat = "DD.MM.YYYY HH:mm:ss";
    const [isLoading, setIsLoading] = useState(false);
    const [sensorListData, setSensorListData] = useState([]);
    const [limit, setLimit] = useState(5);
    const [pagination, setPagination] = useState({});
    const [formData, setFormData] = useState({});
    const [searchValue, setSearchValue] = useState();

    useEffect(() => {
        customFetch({offset: 1, limit: limit, orderBy: 'sensorName', order: 'descend'});
    }, []);
    
    useEffect(() => {
      if (refresh === true)
        customFetch({offset: 1, limit: limit, orderBy: 'sensorName', order: 'descend'});
      
      setRefresh(false);
    }, [refresh]);

    const customFetch = async (searchCriteria = {}) => {

      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/sensor/building?buildingId='+buildingId, {
        params: searchCriteria
      }).then(response => {
        setSensorListData(response.data.content);
        setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'sensorName', order: 'descend', total: response.data.totalElements, pageSizeOptions: ['5','10','20','50','100']});
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const handleTableChange = (
        pagination,
        filters,
        sorter
      ) => {
        if (sorter.columnKey === undefined)
          sorter.columnKey = pagination.orderBy;

        customFetch({
          offset: pagination.current,
          limit: pagination.pageSize,
          orderBy: sorter.columnKey,
          order: sorter.order,
          ...formData
        });
    };

    const onSearch = (searchKey) => {
      var search = {search: searchKey+":"+searchValue};
      customFetch({
        offset: 1,
        limit: limit,
        orderBy: 'sensorName',
        ...search
      });
    };

    function getColumnSearchProps(dataIndex) {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={'Hladať'}
              onChange={e => setSearchValue(e.target.value ? [e.target.value] : [])}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => onSearch(dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Hladať
              </Button>
              <Button
                onClick={() => {
                  confirm({ closeDropdown: true });
                }}
                icon={<CloseOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Zavrieť
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      }
    }

    const columns = [
        {
          title: 'Názov',
          dataIndex: 'sensorName',
          key: 'sensorName',
          ...getColumnSearchProps('sensorName'),
          sorter: (a, b) => a.sensorName - b.sensorName,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => 
          <div>
            <div>
              <Link to={process.env.REACT_APP_ENEGRAF_URL + "/sensor/building/"+buildingId+"/"+record.sensorType+"/"+record.sensorName}>{record.sensorName}</Link>
            </div>
          </div>
        },{
          title: 'Typ',
          dataIndex: 'sensorType',
          key: 'sensorType',
          ...getColumnSearchProps('sensorType'),
          sorter: (a, b) => a.sensorType - b.sensorType,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["lg"]
        },{
          title: 'Použitie',
          dataIndex: 'sensorPurpose',
          key: 'sensorPurpose',
          sorter: (a, b) => a.sensorPurpose - b.sensorPurpose,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, record, index) => {
            if (record.sensorPurpose === 'TV') 
              return 'Teplá voda';
            else if (record.sensorPurpose === 'SV') 
              return 'Studená voda';
            else if (record.sensorPurpose === 'PLN') 
              return 'Plyn';
            else if (record.sensorPurpose === 'EL') 
              return 'Elektrina';
            else
              return 'Energie';
          }
        },{
          title: 'Vlastník',
          dataIndex: 'owner',
          key: 'owner',
          ...getColumnSearchProps('owner'),
          sorter: (a, b) => a.owner - b.owner,
          sortDirections: ['ascend', 'descend', 'ascend'],
        },{
          title: 'Stav merača',
          dataIndex: 'value',
          key: 'value',
          render: (text, record, index) => parseFloat(record.value).toFixed(3) + ' ' + record.unit
        },{
          title: 'Meter ID',
          dataIndex: 'meterId',
          key: 'meterId',
          sorter: (a, b) => a.meterId - b.meterId,
          sortDirections: ['ascend', 'descend', 'ascend'],
          responsive: ["lg"]
        }
    ];

    return (
      <Table
        rowKey={sensorListData => sensorListData.uid}
        columns={columns}
        dataSource={sensorListData}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
        size="small"
      />
    );
};

export default BuildingSensorsTable;